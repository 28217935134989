import React, { useState } from 'react';
import { CheckedMenu, Menu, MenuForm, MenuList } from '../../components';
import { Page } from '../../components/page/Page';
import { buildLogSearchUrl } from '../../utils/DataDog';
import { Alert, AlertColor, Box, Button } from '@mui/material';
import NewMenuPushForm from '../../components/modals/NewMenuPushModal';
import {
  MENU_UPDATE_PERMISSION,
  useAuthorizer,
} from '../../packages/okta-auth';

export const Menus = (): JSX.Element => {
  const { hasPermission } = useAuthorizer();
  const [menuList, setMenuList] = useState<Menu[]>([]);
  const [updatedMenus, setUpdatedMenus] = useState<CheckedMenu[]>([]);
  const checkedMenus = updatedMenus.filter((menu) => menu.isChecked);
  const [showNewMenuModal, setShowNewMenuModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState('');
  const [shouldAlert, setShouldAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');
  const openNewMenuPushModal = () => setShowNewMenuModal(true);
  const closeNewMenuPushModal = () => setShowNewMenuModal(false);
  const canUpdateMenu = hasPermission(MENU_UPDATE_PERMISSION);
  return (
    <Page
      header="Menus"
      component={
        <>
          <NewMenuPushForm
            onClose={closeNewMenuPushModal}
            open={showNewMenuModal}
            partner={selectedPartner}
            onSuccess={(message: string) => {
              setAlertSeverity('success');
              setAlertMessage(message);
              setShouldAlert(true);
            }}
          />
          <MenuForm
            setMenuList={setMenuList}
            checkedMenus={checkedMenus}
            setSelectedPartner={setSelectedPartner}
            selectedPartner={selectedPartner}
          />
          {shouldAlert && (
            <Alert
              variant="filled"
              severity={alertSeverity}
              onClose={() => {
                setShouldAlert(false);
              }}
            >
              {alertMessage}
            </Alert>
          )}
          {selectedPartner && canUpdateMenu && (
            <Box mb={2} display="flex">
              <Button type="submit" onClick={openNewMenuPushModal}>
                Push New Menu
              </Button>
            </Box>
          )}
        </>
      }
      instructions={
        <ul>
          <li>Ability to push menus on a location, partner.</li>
          <li>See status of current menu push - optional.</li>
          <li>Accurate picture of last successful menu push.</li>
          <li>
            Note: DoorDash is different, wherein the log only shows when a menu
            is ready and not that the menu has been ingested.
          </li>
          <li>The table will automatically update.</li>
          <li>
            To monitor menu pushes, refer to the
            <a
              target="_blank"
              rel="noreferrer"
              href={buildLogSearchUrl('Chainwide_Menu_*')}
              style={{ marginLeft: '.3rem' }}
            >
              DataDog Logs
            </a>
            .
          </li>
        </ul>
      }
      table={<MenuList {...{ menus: menuList, setUpdatedMenus }} />}
    />
  );
};
