import { PartnerResults } from '../../api/types';
import { PartnerLocationMappingTable } from './PartnerLocationMappingTable';

interface PartnerLocationMappingProps {
  partnerResults: PartnerResults | undefined;
}

export const PartnerLocationMapping = ({
  partnerResults,
}: PartnerLocationMappingProps): JSX.Element => {
  if (
    partnerResults?.doordash !== undefined ||
    partnerResults?.grubhub !== undefined ||
    partnerResults?.ubereats !== undefined
  ) {
    return (
      <PartnerLocationMappingTable
        partners={[
          {
            partner: 'GrubHub',
            inLocationMappingFile:
              partnerResults.grubhub?.location_mapping != undefined,
            webLink:
              partnerResults.grubhub?.location_mapping?.storeURL ??
              'No mapping found',
          },
          {
            partner: 'UberEats',
            inLocationMappingFile:
              partnerResults.ubereats?.location_mapping != undefined,
            webLink:
              partnerResults.ubereats?.location_mapping?.storeURL ??
              'No mapping found',
          },
          {
            partner: 'DoorDash',
            inLocationMappingFile:
              partnerResults.doordash?.location_mapping != undefined,
            webLink:
              partnerResults.doordash?.location_mapping?.storeURL ??
              'No mapping found',
          },
        ]}
      />
    );
  } else {
    return <PartnerLocationMappingTable partners={[]} />;
  }
};
export default PartnerLocationMapping;
