import { ChangeAudit, LastEvaluatedKey, PaginatedChangeAudit } from './types';
import { getBaseURL, getHeaders, handleErrors } from './utils';

export const getChangeHistory = (): Promise<ChangeAudit[]> =>
  fetch(`${getBaseURL()}/portal/audit/readlogs`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(handleErrors);

export const searchChangeHistory = (
  term: string,
  limit: number,
  lastEvalKey: LastEvaluatedKey | undefined
): Promise<PaginatedChangeAudit> =>
  fetch(`${getBaseURL()}/portal/audit/search?search=${term}&limit=${limit}`, {
    method: 'POST',
    headers: getHeaders(),
    body: lastEvalKey ? JSON.stringify(lastEvalKey) : lastEvalKey,
  }).then(handleErrors);
