import { FailureDetails, SuccessDetails } from '.';
import { MenuMapping } from '../components/menu_id_report';
import { getBaseURL, getHeaders, handleErrors } from './utils';

export interface MenuDTO {
  partner: string;
  locationNumber: string;
  lastSuccessfulUpdate: number;
}

export interface PushMenuResponse {
  failure: FailureDetails[];
  success: SuccessDetails[];
}

export interface MenuIDReportDTO {
  provider_name: string;
  merchant_supplied_id: string;
  menus: MenuIDReportMenuDTO[];
}

export interface MenuIDReportMenuDTO {
  menu_id: string;
  name: string;
  subtitle: string;
  is_active: boolean;
  is_pos_menu: boolean;
  last_successful_menu_update_at: string;
  latest_menu_update: MenuIDReportMenuUpdateDTO;
  url: string;
}

export interface MenuIDReportMenuUpdateDTO {
  created_at: string;
  status: string;
}

export const fetchMenus = (partner: string): Promise<MenuDTO[]> =>
  fetch(`${getBaseURL()}/portal/menu/successfulupdates/${partner}`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(handleErrors);

export const pushMenu = (
  partner: string,
  locationNumber: string[]
): Promise<PushMenuResponse> =>
  fetch(`${getBaseURL()}/portal/menu/update`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      [partner]: locationNumber,
    }),
  }).then(handleErrors);

export const fetchMenuIDReport = (location: string): Promise<MenuIDReportDTO> =>
  fetch(`${getBaseURL()}/portal/menu/${location}/report`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(handleErrors);

export const convertMenuIDReportToMenuMapping = (
  menuIDReport: MenuIDReportDTO
): MenuMapping[] => {
  const result = menuIDReport.menus.map((menu) => {
    return {
      locationNumber: menuIDReport.merchant_supplied_id,
      menuID: menu.menu_id,
      menuName: menu.name,
      lastAttemptedUpdate: new Date(
        menu.latest_menu_update.created_at
      ).toString(),
      lastAttemptedStatus: menu.latest_menu_update.status,
      lastSuccessUpdated: new Date(
        menu.last_successful_menu_update_at
      ).toString(),
    };
  });
  return result;
};

export const updateMenuChainwide = (partner: string): Promise<string> =>
  fetch(`${getBaseURL()}/portal/menu/chainwide/update/` + partner, {
    method: 'POST',
    headers: getHeaders(),
  }).then(handleErrors);
