import { Config, EnvConfig, ENVS } from './types';

const config: Config = {
  env: {
    local: {
      Okta: {
        issuer: 'https://cfahome.oktapreview.com/oauth2/auscjsakz3PaAP82K0h7',
        clientId: '0oau51pa0d5rJETr50h7',
      },
      apiBaseUrl: '/api',
    },
    dev: {
      Okta: {
        issuer: 'https://cfahome.oktapreview.com/oauth2/auscjsakz3PaAP82K0h7',
        clientId: '0oau51pa0d5rJETr50h7',
      },
      apiBaseUrl: 'https://partner.api.dev.crndev.chick-fil-a.com',
    },
    qa: {
      Okta: {
        issuer: 'https://cfahome.oktapreview.com/oauth2/auscjsakz3PaAP82K0h7',
        clientId: '0oau51pa0d5rJETr50h7',
      },
      apiBaseUrl: 'https://partner.api.qa.crndev.chick-fil-a.com',
    },
    prod: {
      Okta: {
        issuer: 'https://cfahome.okta.com/oauth2/aus4i6zex3F52d4rn1t7',
        clientId: '0oafm8pia7rkn0IcY1t7',
      },
      apiBaseUrl: 'https://partner.api.my.chick-fil-a.com',
    },
  },
};

export const getEnv = (): ENVS => {
  const url = window.location.hostname;

  if (url.includes('dev.partyportal.crndev.chick-fil-a.com')) {
    return ENVS.DEV;
  } else if (
    url.includes('local.partyportal.crndev.chick-fil-a.com') ||
    url.includes('qa.partyportal.crndev.chick-fil-a.com') ||
    url.includes('dev.my.chick-fil-a.com')
  ) {
    return ENVS.QA;
  } else if (url.includes(`partyportal.my.chick-fil-a.com`)) {
    return ENVS.PROD;
  } else {
    return ENVS.LOCAL;
  }
};

export const getEnvConfig = (): EnvConfig => {
  return config.env[getEnv()];
};
