import { Typography, Box } from '@mui/material';
import { DDStoreInfo } from '../../api/types';
import { getMiles } from '../../utils/GetMiles';

interface PartnerResultProps {
  info: DDStoreInfo | undefined;
}

export const DoorDashInfo = ({ info }: PartnerResultProps): JSX.Element => {
  return (
    <Box>
      <Typography my={1} textAlign="center">
        <strong>DoorDash</strong>
      </Typography>
      {info?.merchant_supplied_id && (
        <Typography variant="body1">{`Merchant Supply ID: ${info?.merchant_supplied_id}`}</Typography>
      )}
      {info?.is_order_protocol_pos && (
        <Typography variant="body1">{`Order Protocol POS: ${info?.is_order_protocol_pos
          ?.toString()
          .toUpperCase()}`}</Typography>
      )}
      {info?.auto_release_enabled && (
        <Typography variant="body1">{`Auto Release Enabled: ${info?.auto_release_enabled
          ?.toString()
          .toUpperCase()}`}</Typography>
      )}
      {info?.auto_release_distance && (
        <Typography variant="body1">{`Auto Release Distance: ${getMiles(
          info?.auto_release_distance
        )} miles`}</Typography>
      )}
      {info?.doordash_store_url && (
        <>
          <Typography variant="body1">Website:</Typography>
          <a target="_blank" rel="noreferrer" href={info?.doordash_store_url}>
            {info?.doordash_store_url}
          </a>
        </>
      )}

      {info?.current_deactivations &&
        info?.current_deactivations.map((it) => (
          <Box key={it.created_at}>
            <Typography variant="body1">Current Deactivations: </Typography>
            <Typography variant="body1">{`Reason: ${it.reason}`}</Typography>
            <Typography variant="body1">{`Notes: ${it.notes}`}</Typography>
            <Typography variant="body1">{`Created At: ${it.created_at}`}</Typography>
            <Typography variant="body1">{`End Time: ${it.end_time}`}</Typography>
          </Box>
        ))}
    </Box>
  );
};
export default DoorDashInfo;
