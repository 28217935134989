import { Typography, Box } from '@mui/material';
import { UEStoreInfo } from '../../api/types';

interface PartnerResultProps {
  info: UEStoreInfo | undefined;
}

export const UberEatsInfo = ({ info }: PartnerResultProps): JSX.Element => {
  return (
    <Box>
      <Typography my={1} textAlign="center">
        <strong>Uber Eats</strong>
      </Typography>
      {info?.name && (
        <Typography variant="body1">{`Name: ${info?.name}`}</Typography>
      )}
      {info?.merchant_id && (
        <Typography variant="body1">{`Merchant ID: ${info?.merchant_id}`}</Typography>
      )}
      {info?.uuid && (
        <Typography variant="body1">{`UUID: ${info?.uuid}`}</Typography>
      )}
      {info?.web_url && (
        <Typography variant="body1">
          {'Web URL: '}
          <a target="_blank" rel="noreferrer" href={info?.web_url}>
            {info?.web_url}
          </a>
        </Typography>
      )}
      {info?.status && (
        <Typography variant="body1">{`Status: ${info?.status.toUpperCase()}`}</Typography>
      )}
      {info?.pos_data.integration_enabled && (
        <Typography variant="body1">{`POS Integration Enabled: ${info?.pos_data.integration_enabled
          ?.toString()
          .toUpperCase()}`}</Typography>
      )}
    </Box>
  );
};
export default UberEatsInfo;
