import { LocationMappingDTO } from '../api/location_mapping';
import { LocationMapping } from '../components/partner_location_mapping_container';

export const mapLocationMappingDTOtoLocationMapping = (
  lmDTO: LocationMappingDTO
): LocationMapping => {
  return {
    locationNumber: lmDTO.locationNumber,
    storeURL: lmDTO.storeURL,
    uuid: lmDTO.uuid,
    lastUpdated: lmDTO.lastUpdated,
  };
};
