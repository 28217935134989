import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    textButton: {
      fontSize: '14px',
      color: theme.palette.primary.light,
      margin: theme.spacing(0, 1),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0.5, 0),
      },
    },
  })
);

export enum TextButtonVariant {
  Cancel = 'cancel',
}

interface TextButtonProps {
  id: string;
  variant: TextButtonVariant;
  onClick?: () => void;
  className?: string;
  fullWidth?: boolean;
  to?: string;
}

const labelForVariant = (variant: TextButtonVariant) => {
  switch (variant) {
    case TextButtonVariant.Cancel:
      return 'Cancel';
  }
};

export const TextButton: React.FC<TextButtonProps> = ({
  id,
  variant,
  onClick = undefined,
  className = '',
  fullWidth,
  to = undefined,
}) => {
  const classes = useStyles();

  return (
    <Button
      id={id}
      className={`${classes.textButton} ${className}`}
      onClick={onClick}
      fullWidth={fullWidth}
      component={to ? Link : 'button'}
      to={to}
    >
      {labelForVariant(variant)}
    </Button>
  );
};

export default TextButton;
