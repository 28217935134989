import { MenuDTO } from '../api/menu';
import { Menu } from '../components/menu';

export const Nanoseconds = 1000000;

export const mapMenuDTOtoMenu = (menuDTO: MenuDTO): Menu => {
  return {
    locationNumber: menuDTO.locationNumber,
    lastSuccessfulUpdate: new Date(menuDTO.lastSuccessfulUpdate / Nanoseconds), // Transform Unix epoch time to milliseconds
  };
};
