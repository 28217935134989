export type OktaCfg = {
  issuer: string;
  clientId: string;
};

export type EnvConfig = {
  readonly Okta: OktaCfg;
  readonly apiBaseUrl: string;
};

export type Config = {
  env: {
    local: EnvConfig;
    dev: EnvConfig;
    qa: EnvConfig;
    prod: EnvConfig;
  };
};

export enum ENVS {
  LOCAL = 'local',
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
}
