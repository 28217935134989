import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import { Configuration } from '../../api/configuration';
import makeStyles from '@mui/styles/makeStyles';

interface ConfigurationFormProps {
  partners: string[];
  configurations: Configuration[];
  updatePartner: (partner: string) => void;
  filterConfigurations: (key: string) => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      minWidth: 240,
    },
    marginBottom: 24,
  },
  storeIdFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  buttonProgress: {
    position: 'absolute',
    zIndex: 1,
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  updateResults: {
    margin: '8px',
  },
}));

export const ConfigurationForm = ({
  partners,
  configurations,
  updatePartner,
  filterConfigurations,
}: ConfigurationFormProps): JSX.Element => {
  const classes = useStyles();

  const [selectedPartner, setSelectedPartner] = useState('');
  const [selectedConfiguration, setSelectedConfiguration] = useState('');

  return (
    <Box className={classes.form}>
      <FormControl variant="outlined" size="small">
        <InputLabel id="select-partner-label">Partner</InputLabel>
        <Select
          fullWidth
          id="select-partner"
          name="selectedPartner"
          value={selectedPartner}
          labelId="select-partner-label"
          label="Partner"
          onChange={(event) => {
            const partner = event.target.value;

            setSelectedPartner(partner);
            updatePartner(partner);
          }}
        >
          {Object.values(partners).map((partner, index) => (
            <MenuItem key={index} value={partner}>
              <ListItemText primary={partner} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" size="small">
        <InputLabel id="select-config-options-label">Config Options</InputLabel>
        <Select
          id="select-config-options"
          name="selectedOption"
          value={selectedConfiguration}
          labelId="select-config-options-label"
          label="Config Options"
          onChange={(event) => {
            const configuration = event.target.value;

            setSelectedConfiguration(configuration);
            filterConfigurations(configuration);
          }}
        >
          {configurations &&
            configurations.map(({ key }) => (
              <MenuItem key={key} value={key}>
                <ListItemText
                  primary={key
                    .replaceAll(selectedPartner, '')
                    .replaceAll('/', ' ')
                    .trim()}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};
