import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { getEnvConfig } from '../config';
import { useOktaAuth } from '@okta/okta-react';

export const oktaAuth = new OktaAuth({
  issuer: getEnvConfig().Okta.issuer,
  clientId: getEnvConfig().Okta.clientId,
  redirectUri: window.location.origin + '/implicit/callback',
});

interface PermissionsMap {
  [index: string]: string[];
}

export const AUDIT_READ_PERMISSION = 'portal:audit:read';
export const CHAINWIDE_MENU_PERMISSION = 'portal:menu:chainwide_update';
export const CONFIG_UPDATE_PERMISSION = 'portal:config:update';
export const CONFIG_READ_PERMISSION = 'portal:config:read';
export const LOCATION_READ_PERMISSION = 'portal:location:read';
export const MAPPING_READ_PERMISSION = 'portal:mapping:read';
export const MAPPING_UPDATE_PERMISSION = 'portal:mapping:update';
export const MENU_READ_PERMISSION = 'portal:menu:read';
export const MENU_UPDATE_PERMISSION = 'portal:menu:update';

// this is a copy of the role to permissions map in dxe-config used by the partyportal back-end to authorize requests
// TODO: add a backend endpoint that returns this data so it stays centralized
const roleToPermissionsMap: PermissionsMap = {
  '3PI_VIEW': [
    LOCATION_READ_PERMISSION,
    MAPPING_READ_PERMISSION,
    MENU_READ_PERMISSION,
  ],
  '3PI_OPS_SUPPORT': [
    LOCATION_READ_PERMISSION,
    MENU_READ_PERMISSION,
    MENU_UPDATE_PERMISSION,
    MAPPING_READ_PERMISSION,
    MAPPING_UPDATE_PERMISSION,
  ],
  '3PI_ADMIN': [
    CONFIG_READ_PERMISSION,
    CONFIG_UPDATE_PERMISSION,
    MENU_READ_PERMISSION,
    MENU_UPDATE_PERMISSION,
    CHAINWIDE_MENU_PERMISSION,
    LOCATION_READ_PERMISSION,
    AUDIT_READ_PERMISSION,
    MAPPING_READ_PERMISSION,
    MAPPING_UPDATE_PERMISSION,
  ],
};

interface Authorizer {
  hasPermission: (requiredPermission: string) => boolean;
  isAuthenticated: boolean;
  oktaAuth: OktaAuth;
  authState: AuthState | null;
}

export const useAuthorizer = (): Authorizer => {
  const result = useOktaAuth();
  const { authState, oktaAuth } = result;
  const roles = authState?.accessToken?.claims.cfa_perms.PARTYPORTAL ?? {};
  const permissions = Object.keys(roles).flatMap(
    (role) => roleToPermissionsMap[role] ?? []
  );
  const hasPermission = (requiredPermission: string): boolean =>
    permissions.includes(requiredPermission);
  return {
    hasPermission,
    isAuthenticated: authState?.isAuthenticated ?? false,
    authState,
    oktaAuth,
  };
};
