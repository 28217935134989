import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactElement, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  convertMenuIDReportToMenuMapping,
  fetchMenuIDReport,
} from '../../api/menu';
import Alert from '@mui/material/Alert';
import { convertToLocalTimezone } from '../../utils/ConvertToLocalTimezone';

const useStyles = makeStyles(() => ({
  table: {
    display: 'block',
    maxWidth: 3500,
  },
}));

export interface MenuMapping {
  locationNumber: string;
  menuID: string;
  menuName: string;
  lastAttemptedUpdate: string;
  lastAttemptedStatus: string;
  lastSuccessUpdated: string;
}

interface MenuIdReportProps {
  location: string;
}

const renderTableRow = ({
  locationNumber,
  menuID,
  menuName,
  lastAttemptedUpdate,
  lastAttemptedStatus,
  lastSuccessUpdated,
}: MenuMapping): ReactElement => {
  return (
    <TableRow
      key={menuID}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {locationNumber}
      </TableCell>
      <TableCell>{menuID}</TableCell>
      <TableCell>{menuName}</TableCell>
      <TableCell>
        {convertToLocalTimezone(lastAttemptedUpdate)} {lastAttemptedStatus}
      </TableCell>
      <TableCell>{convertToLocalTimezone(lastSuccessUpdated)}</TableCell>
    </TableRow>
  );
};

export const MenuIDReportList = ({
  location,
}: MenuIdReportProps): JSX.Element => {
  const classes = useStyles();

  const [menuMappingList, setMenuMappingList] = useState<MenuMapping[]>([]);
  const [shouldAlert, setShouldAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  /** Load menu id reports for current location being searched */
  useEffect(() => {
    if (location)
      fetchMenuIDReport(location)
        .then((result) => {
          const mappingList = convertMenuIDReportToMenuMapping(result);
          setMenuMappingList(mappingList);
        })
        .catch((error) => {
          setMenuMappingList([]);
          setShouldAlert(true);
          if (error instanceof Error) {
            setAlertMessage(error.message);
          } else {
            setAlertMessage(
              `There was a problem generating the menu reports: ${error}`
            );
          }
        });
  }, [location]);

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table sx={{ minWidth: 300 }} size="small" aria-label="Menu Mappings">
        {location ? (
          <TableHead>
            <TableRow>
              <TableCell>Store #</TableCell>
              <TableCell>Menu ID</TableCell>
              <TableCell>Menu Name</TableCell>
              <TableCell>Last Attempted Menu Update</TableCell>
              <TableCell>Last Successfully Updated</TableCell>
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {menuMappingList.map((menuMapping) =>
            renderTableRow({ ...menuMapping })
          )}
        </TableBody>
      </Table>
      {shouldAlert && (
        <Alert variant="filled" severity="error">
          {alertMessage}
        </Alert>
      )}
    </TableContainer>
  );
};
