import React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    containedButton: {
      fontSize: '14px',
      background: theme.palette.primary.main,
      margin: theme.spacing(0, 1),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0.5, 0),
      },
    },
  })
);

export enum ContainedButtonVariant {
  Add = 'add',
  Edit = 'edit',
  More = 'more',
}

interface ContainedButtonProps {
  id: string;
  variant: ContainedButtonVariant;
  onClick?: () => void;
  fullWidth?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  to?: string;
}

const labelForVariant = (variant: ContainedButtonVariant) => {
  switch (variant) {
    case ContainedButtonVariant.Add:
      return 'Add';
    case ContainedButtonVariant.Edit:
      return 'Edit';
    case ContainedButtonVariant.More:
      return 'More';
  }
};

const ContainedButton: React.FC<ContainedButtonProps> = ({
  id,
  variant,
  onClick = undefined,
  fullWidth,
  className = '',
  type = 'button',
  disabled,
  to = undefined,
}) => {
  const classes = useStyles();

  return (
    <Button
      id={id}
      color="secondary"
      variant="contained"
      onClick={onClick}
      classes={{
        contained: `${classes.containedButton} ${className}`,
      }}
      fullWidth={fullWidth}
      type={type}
      disabled={disabled}
      component={to ? Link : 'button'}
      to={to}
    >
      {labelForVariant(variant)}
    </Button>
  );
};

export default ContainedButton;
