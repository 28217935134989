import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SyntheticEvent } from 'react';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) =>
  createStyles({
    searchBarContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
  })
);

interface AutoCompleteSearchBarProps {
  onChange: (
    event: SyntheticEvent<Element, Event>,
    newValue: string | null
  ) => void;
  autoCompleteOptions: string[];
  placeholder?: string;
}

const AutoCompleteSearchBar = ({
  onChange,
  placeholder,
  autoCompleteOptions,
}: AutoCompleteSearchBarProps): JSX.Element => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');

  return (
    <Box className={classes.searchBarContainer}>
      <Autocomplete
        value={searchText}
        id="search-input"
        options={
          autoCompleteOptions
            ? autoCompleteOptions.map((option) => option)
            : ['']
        }
        onChange={(
          event: SyntheticEvent<Element, Event>,
          newValue: string | null
        ) => {
          onChange(event, newValue);
          setSearchText(newValue ?? '');
        }}
        inputValue={searchText}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            onChange={(event) => {
              onChange(event, event.target.value);
              setSearchText(event.target.value);
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutoCompleteSearchBar;
