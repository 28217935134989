import { Box } from '@mui/material';
import { DDStoreInfo, GHStoreInfo, UEStoreInfo } from '../../api/types';
import DoorDashInfo from './DoorDashInfo';
import GrubHubInfo from './GrubHubInfo';
import UberEatsInfo from './UberEatsInfo';

interface PartnerResultsProps {
  doordash: DDStoreInfo | undefined;
  grubhub: GHStoreInfo | undefined;
  ubereats: UEStoreInfo | undefined;
}

export const PartnerInfoDisplay = ({
  doordash,
  grubhub,
  ubereats,
}: PartnerResultsProps): JSX.Element => {
  return (
    <Box>
      {doordash !== undefined && <DoorDashInfo info={doordash} />}
      {grubhub !== undefined && <GrubHubInfo info={grubhub} />}
      {ubereats !== undefined && <UberEatsInfo info={ubereats} />}
    </Box>
  );
};
export default PartnerInfoDisplay;
