import { getEnvConfig } from '../packages/config';
import { getAuthToken } from './auth';

export const getHeaders = (): HeadersInit => ({
  'Content-Type': 'application/json',
  Accept: '*/*',
  Authorization: `JWTBearer ${getAuthToken()}`,
});

export const getBaseURL = (): string => {
  return getEnvConfig().apiBaseUrl;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleErrors = (res: Response): any => {
  if (!res.ok) {
    throw Error(res.statusText);
  }

  if (res.headers.get('content-type')?.includes('application/json')) {
    return res.json();
  } else {
    return res.text();
  }
};
