import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ReactElement, useEffect, useState } from 'react';
import TableFooterPagination from '../pagination/TableFooterPagination';
import { Configuration } from '../../api/configuration';
import { truncateString } from '../../utils/TruncateString';

interface ConfigurationTableProps {
  configurationMappings: Configuration[];
  onClick?: (args: {
    isEdit: boolean;
    isDelete: boolean;
    key?: string;
    value?: string;
    title?: string;
    description?: string;
  }) => void;
}

const emptyConfigurationMappings: Configuration[] = [
  { key: '', value: '', title: '', description: '' },
];

const renderTableRow = ({
  key,
  value,
  title,
  description,
  onClick,
}: Configuration): ReactElement => {
  const handleEdit = (
    key: string,
    value: string,
    title: string,
    description: string
  ) => {
    if (onClick)
      onClick({
        isEdit: true,
        isDelete: false,
        key,
        value,
        title,
        description,
      });
  };

  const handleDelete = (key: string) => {
    if (onClick) onClick({ isEdit: false, isDelete: true, key });
  };

  return (
    <TableRow
      key={key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Typography>{key}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{description}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{title}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{truncateString(value, 35)}</Typography>
      </TableCell>
      <>
        <TableCell>
          <Button onClick={() => handleEdit(key, value, title, description)}>
            Edit
          </Button>
        </TableCell>
        <TableCell>
          <Button onClick={() => handleDelete(key)}>Delete</Button>
        </TableCell>
      </>
    </TableRow>
  );
};

export const ConfigurationTable = ({
  configurationMappings,
  onClick,
}: ConfigurationTableProps): JSX.Element => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  /**
   * We always set page to 0 to account for filtering
   */
  useEffect(() => {
    setPage(0);
  }, [configurationMappings]);

  const tableRows = configurationMappings
    ? [
        ...configurationMappings.map((configuration) =>
          renderTableRow({ ...configuration, onClick } as Configuration)
        ),
      ]
    : emptyConfigurationMappings;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 300 }}
        size="small"
        aria-label="Configuration Mappings"
      >
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Value</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {configurationMappings && configurationMappings.length > 0
            ? rowsPerPage > 0
              ? tableRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableRows
            : null}
        </TableBody>
        {configurationMappings && configurationMappings.length > 10 && (
          <TableFooterPagination
            count={(configurationMappings && configurationMappings.length) ?? 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            colSpan={6}
          />
        )}
      </Table>
    </TableContainer>
  );
};
