import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import {
  MENU_UPDATE_PERMISSION,
  useAuthorizer,
} from '../../packages/okta-auth';

const useStyles = makeStyles(() => ({
  table: {
    display: 'block',
    maxWidth: 500,
  },
}));

export interface Menu {
  locationNumber: string;
  lastSuccessfulUpdate: Date;
}

export interface CheckedMenu extends Menu {
  isChecked: boolean;
  handleSwitch?: (it: any) => any;
  canUpdateMenu?: boolean;
}

interface MenuListProps {
  menus: Menu[];
  setUpdatedMenus: Dispatch<SetStateAction<CheckedMenu[]>>;
}

const isTwentyFourHoursAgo = (value: Date): boolean => {
  const twentyFourHoursAgo = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  );

  return value <= twentyFourHoursAgo;
};

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: red[100],
}));

const renderStyledTableRow = ({
  locationNumber,
  lastSuccessfulUpdate,
  isChecked,
  handleSwitch,
  canUpdateMenu,
}: CheckedMenu): ReactElement => {
  return (
    <StyledTableRow
      key={locationNumber}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {locationNumber}
      </TableCell>

      <TableCell>{lastSuccessfulUpdate.toString()}</TableCell>
      {canUpdateMenu && (
        <TableCell>
          <Switch
            data-testid={`push-menu-toggle-${locationNumber}`}
            checked={isChecked}
            onChange={handleSwitch}
          />
        </TableCell>
      )}
    </StyledTableRow>
  );
};

const renderTableRow = ({
  locationNumber,
  lastSuccessfulUpdate,
  isChecked,
  handleSwitch,
  canUpdateMenu,
}: CheckedMenu): ReactElement => {
  return (
    <TableRow
      key={locationNumber}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {locationNumber}
      </TableCell>
      <TableCell>{lastSuccessfulUpdate.toString()}</TableCell>
      {canUpdateMenu && (
        <TableCell>
          <Switch
            data-testid={`push-menu-toggle-${locationNumber}`}
            checked={isChecked}
            onChange={handleSwitch}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export const MenuList = ({
  menus,
  setUpdatedMenus,
}: MenuListProps): JSX.Element => {
  const classes = useStyles();
  const { hasPermission } = useAuthorizer();
  const [checkedLocations, setCheckedLocations] = useState<CheckedMenu[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const canUpdateMenu = hasPermission(MENU_UPDATE_PERMISSION);
  /**
   * Ensure all stores are unchecked when page initially loads or a
   * new partner is selected.
   */
  useEffect(() => {
    setCheckedLocations(
      menus.map((menu): CheckedMenu => {
        return {
          locationNumber: menu.locationNumber,
          lastSuccessfulUpdate: menu.lastSuccessfulUpdate,
          isChecked: false,
        };
      })
    );

    setCheckAll(false);
  }, [menus]);

  const handleSwitch = (locationNumber: string) => {
    const updatedLocations = checkedLocations.map((item) => {
      if (item.locationNumber == locationNumber) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });

    setCheckedLocations(updatedLocations);
    setUpdatedMenus(updatedLocations);
  };

  const handleCheckAll = () => {
    const updatedLocations = checkedLocations.map((item) => {
      if (checkedLocations.every((menu) => menu.isChecked)) {
        setCheckAll(false);
        return { ...item, isChecked: false };
      } else if (checkAll && checkedLocations.some((menu) => menu.isChecked)) {
        setCheckAll(false);
        return { ...item, isChecked: false };
      } else {
        setCheckAll(true);
        return { ...item, isChecked: true };
      }
    });

    setCheckedLocations(updatedLocations);
    setUpdatedMenus(updatedLocations);
  };

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table sx={{ minWidth: 300 }} size="small" aria-label="Location Menus">
        <TableHead>
          <TableRow>
            <TableCell>Store</TableCell>
            <TableCell>Last Update Time</TableCell>
            {menus.length > 0 && canUpdateMenu && (
              <TableCell>
                {checkAll ? 'Deselect All' : 'Select All'}
                <Switch checked={checkAll} onChange={handleCheckAll} />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {checkedLocations.map((menu) =>
            isTwentyFourHoursAgo(menu.lastSuccessfulUpdate)
              ? renderStyledTableRow({
                  locationNumber: menu.locationNumber,
                  lastSuccessfulUpdate: menu.lastSuccessfulUpdate,
                  isChecked: menu.isChecked,
                  handleSwitch: () => handleSwitch(menu.locationNumber),
                  canUpdateMenu,
                })
              : renderTableRow({
                  locationNumber: menu.locationNumber,
                  lastSuccessfulUpdate: menu.lastSuccessfulUpdate,
                  isChecked: menu.isChecked,
                  handleSwitch: () => handleSwitch(menu.locationNumber),
                  canUpdateMenu,
                })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
