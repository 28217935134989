import { Box, Typography } from '@mui/material';
import { MenuUpdate } from '../../api/types';

interface MenuUpdatesProps {
  ddUpdate: MenuUpdate | undefined;
  ghUpdate: MenuUpdate | undefined;
  ueUpdate: MenuUpdate | undefined;
}

const convertNStoMS = (nanosecondEpoch: number): number =>
  nanosecondEpoch / 1000000;

const MenuUpdateStatus = ({
  title,
  menuUpdate,
}: {
  title: JSX.Element | undefined;
  menuUpdate: MenuUpdate;
}): JSX.Element => {
  const updateDate = new Date(convertNStoMS(menuUpdate.updateTime));
  const lastSuccessfulUpdateDate = new Date(
    convertNStoMS(menuUpdate.lastSuccessfulUpdate)
  );
  return (
    <Box>
      {title}
      <Typography my={1}>{`Update Time - ${updateDate}`}</Typography>
      <Typography>Update Status - {menuUpdate.updateState}</Typography>
      {menuUpdate.updateTime !== menuUpdate.lastSuccessfulUpdate && (
        <Typography>{`Last Successful Update - ${lastSuccessfulUpdateDate}`}</Typography>
      )}
    </Box>
  );
};

export const MenuUpdates = ({
  ddUpdate,
  ghUpdate,
  ueUpdate,
}: MenuUpdatesProps): JSX.Element => {
  return (
    <Box>
      {ddUpdate && (
        <MenuUpdateStatus
          title={<Typography variant="h6">DoorDash:</Typography>}
          menuUpdate={ddUpdate}
        />
      )}
      {ghUpdate && (
        <MenuUpdateStatus
          title={<Typography variant="h6">GrubHub:</Typography>}
          menuUpdate={ghUpdate}
        />
      )}

      {ueUpdate && (
        <MenuUpdateStatus
          title={<Typography variant="h6">Uber Eats:</Typography>}
          menuUpdate={ueUpdate}
        />
      )}
    </Box>
  );
};
export default MenuUpdates;
