export const Partners = [
  {
    label: 'DoorDash',
    apiFieldName: 'doordash',
  },
  {
    label: 'Grubhub',
    apiFieldName: 'grubhub',
  },
  {
    label: 'Uber Eats',
    apiFieldName: 'ubereats',
  },
];
