import { Typography, Container, Box } from '@mui/material';

interface PageProps {
  header: string;
  component: JSX.Element;
  instructions: JSX.Element;
  table?: JSX.Element;
}

export const Page = ({
  header,
  component,
  instructions,
  table,
}: PageProps): JSX.Element => {
  return (
    <Container maxWidth={'xl'}>
      <Typography variant="h3" my={3} textAlign="center">
        {header}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            marginRight: { xs: '0', md: '16px' },
            marginBottom: { xs: '24px', md: '0' },
          }}
        >
          {component}
        </Box>

        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
          }}
        >
          <Typography>Use this page to:</Typography>
          {instructions}
        </Box>
      </Box>
      {table ? <Box>{table}</Box> : null}
    </Container>
  );
};
