import { getBaseURL, getHeaders, handleErrors } from './utils';

export interface Configuration {
  key: string;
  value: string;
  title: string;
  description: string;
  onClick?: (args: {
    isEdit: boolean;
    isDelete: boolean;
    key?: string;
    value?: string;
    title?: string;
    description?: string;
  }) => void;
}

export interface OptionType {
  label: string;
  configs: string[];
}

export interface PartnerOptions {
  partner: string;
  options: OptionType[];
}

export interface Partners {
  label: string;
  apiFieldName: string;
}

export const getPartnerOptions = async (): Promise<string[]> => {
  return await fetch(`${getBaseURL()}/portal/config/prefixes`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(handleErrors);
};

export const getConfigurations = async (
  prefix?: string
): Promise<Configuration[]> => {
  if (prefix) {
    return await fetch(`${getBaseURL()}/portal/configs?prefix=/${prefix}`, {
      method: 'GET',
      headers: getHeaders(),
    }).then(handleErrors);
  } else {
    return await fetch(`${getBaseURL()}/portal/configs`, {
      method: 'GET',
      headers: getHeaders(),
    }).then(handleErrors);
  }
};

export const createConfiguration = async (
  configuration: Configuration
): Promise<Configuration[]> => {
  return await fetch(`${getBaseURL()}/portal/config`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(configuration),
  }).then(handleErrors);
};

export const editConfiguration = async (
  configuration: Configuration
): Promise<Configuration[]> => {
  return await fetch(`${getBaseURL()}/portal/config`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(configuration),
  }).then(handleErrors);
};

export const deleteConfiguration = async (key: string): Promise<void> => {
  return await fetch(`${getBaseURL()}/portal/config?key=${key}`, {
    method: 'DELETE',
    headers: getHeaders(),
  }).then(handleErrors);
};

export const cloneStoreConfiguration = async ({
  templateLocationNumber,
  targetLocationNumber,
}: {
  templateLocationNumber: string;
  targetLocationNumber: string;
}): Promise<void> => {
  const response = await fetch(`${getBaseURL()}/portal/config/clone`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({ templateLocationNumber, targetLocationNumber }),
  });
  return handleErrors(response);
};
