import React, { useEffect, useState } from 'react';
import ContainedButton, {
  ContainedButtonVariant,
} from '../buttons/ContainedButton';
import BasicModal from './BasicModal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextButton, { TextButtonVariant } from '../buttons/TextButton';
import { createStyles, makeStyles } from '@mui/styles';
import useValidationMessages from '../../util/UseValidationMessages';

interface AddStoreModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: (
    id: string,
    url: string,
    uuid?: string,
    originalId?: string
  ) => void;
  isEdit: boolean;
  id: string;
  url: string;
  selectedPartner: string;
  uuid?: string;
}

export enum AddStoreValidationMessageTypes {
  Default = 'default',
  StoreId = 'storeId',
  StoreUrl = 'storeUrl',
  StoreUuid = 'storeUuid',
}

const validationMessageMap = useValidationMessages([
  {
    field: AddStoreValidationMessageTypes.Default,
    message: 'Please make sure you have filled out the required fields.',
  },
  {
    field: AddStoreValidationMessageTypes.StoreId,
    message: 'Please enter a valid Store ID.',
  },
  {
    field: AddStoreValidationMessageTypes.StoreUrl,
    message: 'Please enter a valid Store URL.',
  },
  {
    field: AddStoreValidationMessageTypes.StoreUuid,
    message: 'Please enter a valid Store UUID.',
  },
]);

const useStyles = makeStyles((theme) => {
  return createStyles({
    secondaryButton: {
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(1),
      },
    },
    primaryButton: {
      margin: 0,
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });
});

export const AddStoreModal: React.FC<AddStoreModalProps> = ({
  isOpen,
  onClose,
  onClick,
  isEdit,
  id,
  url,
  selectedPartner,
  uuid,
}) => {
  const classes = useStyles();
  const [storeId, setStoreId] = useState('');
  const [storeUrl, setStoreUrl] = useState('');
  const [storeUuid, setStoreUuid] = useState('');
  const [isStoreIdValid, setIsStoreIdValid] = useState(true);
  const [isStoreUrlValid, setIsStoreUrlValid] = useState(true);
  const [isStoreUuidValid, setIsStoreUuidValid] = useState(true);

  // Set Store ID if in edit mode
  useEffect(() => {
    if (isEdit) {
      setStoreId(id);
    }
  }, [id]);

  // Set Store URL if in edit mode
  useEffect(() => {
    if (isEdit) {
      setStoreUrl(url);
    }
  }, [url]);

  // Set Store UUID if in edit mode
  useEffect(() => {
    if (isEdit) {
      setStoreUuid(uuid ?? '');
    }
  }, [url]);

  const handleOnClose = () => {
    onClose();
  };

  const handleStoreIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStoreId(event.target.value);
  };

  const handleStoreUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStoreUrl(event.target.value);
  };

  const handleStoreUuidChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStoreUuid(event.target.value);
  };

  const resetModalState = () => {
    if (isEdit) {
      return;
    } else {
      setStoreId('');
      setStoreUrl('');
      setStoreUuid('');
    }
  };

  const handleOnClick = () => {
    // Check if store id is empty or not a number
    if (storeId === '' || !/^\d+$/.test(storeId)) {
      setIsStoreIdValid(false);
    } else if (storeUrl === '') {
      setIsStoreUrlValid(false);
      // Uber Eats is the only partner that requires a UUID so we check for that here
    } else if (
      (selectedPartner === 'ubereats' && storeUuid === '') ||
      (selectedPartner === 'ubereats' &&
        // This regex pattern ensures we have a canonically formatted UUID (version 1 - 5) and is the appropriate Variant as per RFC4122.
        !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
          storeUuid
        ))
    ) {
      setIsStoreUuidValid(false);
    } else {
      isEdit
        ? onClick(storeId, storeUrl, storeUuid, id)
        : onClick(storeId, storeUrl, storeUuid);
    }
  };

  return (
    <BasicModal
      open={isOpen}
      isOpen={isOpen}
      handleFormSubmit={handleOnClick}
      maxWidth="xs"
      dialogContent={
        <Box>
          {selectedPartner === 'ubereats' && (
            <Box pb={3.5}>
              <TextField
                id="storeUuid"
                variant="filled"
                label="Store UUID"
                value={storeUuid}
                onChange={handleStoreUuidChange}
                fullWidth
                error={!isStoreUuidValid}
                helperText={
                  !isStoreUuidValid
                    ? validationMessageMap.get(
                        AddStoreValidationMessageTypes.StoreUuid
                      )
                    : null
                }
                disabled={isEdit}
              />
            </Box>
          )}
          <Box pb={3.5}>
            <TextField
              id="storeId"
              variant="filled"
              label="Store ID"
              value={storeId}
              onChange={handleStoreIdChange}
              fullWidth
              error={!isStoreIdValid}
              helperText={
                !isStoreIdValid
                  ? validationMessageMap.get(
                      AddStoreValidationMessageTypes.StoreId
                    )
                  : null
              }
              disabled={isEdit}
            />
          </Box>
          <Box pb={3.5}>
            <TextField
              id="storeURL"
              variant="filled"
              label="Store URL"
              value={storeUrl}
              onChange={handleStoreUrlChange}
              fullWidth
              error={!isStoreUrlValid}
              helperText={
                !isStoreUrlValid
                  ? validationMessageMap.get(
                      AddStoreValidationMessageTypes.StoreUrl
                    )
                  : null
              }
            />
          </Box>
        </Box>
      }
      dialogActions={
        <>
          <TextButton
            id="primary"
            variant={TextButtonVariant.Cancel}
            onClick={handleOnClose}
            className={classes.secondaryButton}
          />
          <ContainedButton
            id="primary"
            type="submit"
            variant={
              isEdit ? ContainedButtonVariant.Edit : ContainedButtonVariant.Add
            }
            onClick={handleOnClick}
            className={classes.primaryButton}
          />
        </>
      }
      dialogTitle={isEdit ? 'Edit Store' : 'Add New Store'}
      afterClose={resetModalState}
      labelledBy="addStore-form-title"
    />
  );
};

export default AddStoreModal;
