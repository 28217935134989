import { StyledEngineProvider, colors, CssBaseline } from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { Navigation } from './components';
import { Security } from '@okta/okta-react';
import { oktaAuth } from './packages/okta-auth';
import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

declare module '@mui/styles/defaultTheme' {
  // The disable commands below will only work if the curly braces after Theme do not
  // contain a space.
  //   Bad:  Theme { }
  //   Good: Theme {}
  // If the space is present, linting will fail. If that happens, use 'vi' to edit this
  // file and remove the space between the curly braces.
  // eslint-disable-next-line @typescript-eslint/no-empty-interface, prettier/prettier
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: colors.blue,
  },
});

const App = (): JSX.Element => {
  const navigate = useNavigate();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Navigation />
        </ThemeProvider>
      </StyledEngineProvider>
    </Security>
  );
};

export default App;
