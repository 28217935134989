import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import TextButton, { TextButtonVariant } from '../buttons/TextButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import DialogContent from '@mui/material/DialogContent';
import { cloneStoreConfiguration } from '../../api/configuration';

const validationSchema = yup.object({
  locationNumber: yup
    .string()
    .required('Enter a five digit location number')
    .min(5, 'Location Number cannot be less than 5 digits')
    .max(5, 'Location Number cannot be more than 5 digits'),
});

const useStyles = makeStyles((theme) => {
  return createStyles({
    dialogTitle: {
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
  });
});

interface CloneConfigModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (targetLocationNumber: string) => void;
  templateLocationNumber: string;
}

const CloneConfigModal = ({
  open,
  onClose,
  onSuccess,
  templateLocationNumber,
}: CloneConfigModalProps) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      locationNumber: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await cloneStoreConfiguration({
          templateLocationNumber,
          targetLocationNumber: values.locationNumber,
        });
        onSuccess(values.locationNumber);
        formikHelpers.resetForm();
        onClose();
      } catch (err) {
        formik.setStatus(`Cloning the config failed, ${err}`);
        console.error(err);
      }
    },
    validationSchema,
  });
  return (
    <Dialog
      title="Clone Location Config"
      maxWidth="xs"
      aria-label="clone-config-form"
      fullWidth
      open={open}
    >
      <Box py={2} px={{ xs: 2, lg: 3 }}>
        <DialogTitle className={classes.dialogTitle}>
          <Box>Clone Location Config</Box>
          <Typography>From {templateLocationNumber}</Typography>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent style={{ overflowY: 'unset' }}>
            <Box pb={3.5}>
              <TextField
                id="locationNumber"
                variant="filled"
                fullWidth
                name="locationNumber"
                label="Target Location Number"
                value={formik.values.locationNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.locationNumber &&
                  !!formik.errors.locationNumber
                }
                helperText={
                  formik.touched.locationNumber && formik.errors.locationNumber
                }
              />
            </Box>
            {formik.status && <Alert severity="error">{formik.status}</Alert>}
          </DialogContent>
          <DialogActions>
            <TextButton
              id="secondary"
              variant={TextButtonVariant.Cancel}
              onClick={() => {
                formik.resetForm();
                onClose();
              }}
            />
            <Button
              variant="contained"
              disableElevation
              color="primary"
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {formik.isSubmitting && <CircularProgress size={24} />}
              Clone Configuration
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};
export default CloneConfigModal;
