import {
  Alert,
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { Page } from '../../components/page/Page';
import { ConfigurationForm } from '../../components';
import { Permission } from '../../components/Permission/Permission';
import { useEffect, useState } from 'react';
import {
  Configuration,
  createConfiguration,
  deleteConfiguration,
  editConfiguration,
  getConfigurations,
  getPartnerOptions,
} from '../../api/configuration';
import useIsMounted from '../../utils/UseIsMounted';
import { ConfigurationTable } from '../../components/configuration/ConfigurationTable';
import SearchBar from '../../components/searchbar/SearchBar';
import AddConfigurationModal from '../../components/modals/AddConfigurationModal';
import {
  CONFIG_READ_PERMISSION,
  useAuthorizer,
} from '../../packages/okta-auth';

export const Configurations = (): JSX.Element => {
  const { hasPermission } = useAuthorizer();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [configurations, setConfigurations] = useState<
    Configuration[] | undefined
  >([]);
  const [allConfigurations, setAllConfigurations] = useState<
    Configuration[] | undefined
  >([]);
  const [configurationsFromPartner, setConfigurationsFromPartner] = useState<
    Configuration[] | undefined
  >([]);
  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [partners, setPartners] = useState(['']);
  const [shouldAlert, setShouldAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');
  const [originalKey, setOriginalKey] = useState('');
  const [originalValue, setOriginalValue] = useState('');
  const [originalTitle, setOriginalTitle] = useState('');
  const [originalDescription, setOriginalDescription] = useState('');
  const canReadConfiguration = hasPermission(CONFIG_READ_PERMISSION);
  const setAlerts = (error: unknown, message: string) => {
    setShouldAlert(true);
    if (error instanceof Error) {
      setAlertMessage(error.message);
    } else {
      setAlertMessage(`${message}: ${error}`);
    }
  };

  const fetchConfigurations = async (partner?: string) => {
    try {
      return partner
        ? await getConfigurations(partner)
        : await getConfigurations();
    } catch (error) {
      setAlerts(error, 'There was a problem generating the configurations');
    }
  };

  /**
   * Fetch all partners
   */
  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const fetchedPartners = await getPartnerOptions();

        if (isMounted()) {
          setPartners(fetchedPartners.map((it) => it.replace('/', ' ').trim()));
        }
      } catch (error) {
        setAlerts(error, 'There was a problem generating the partner options');
      }
    };
    fetchPartners();
  }, [isMounted]);

  /**
   * We use this to trigger the useEffect for fetching data programmatically.
   * The value itself is not significant, however when the value changes it will trigger the useEffect
   */
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  /**
   * Fetch all configurations
   */
  useEffect(() => {
    const configurations = async () => {
      try {
        const fetchedConfigurations = await fetchConfigurations();

        if (isMounted()) {
          setAllConfigurations(fetchedConfigurations);
          setConfigurations(fetchedConfigurations);
        }
      } catch (error) {
        setAlerts(error, 'There was a problem generating the configurations');
      }
    };
    configurations();
    setTriggerRefresh(false);
  }, [isMounted, triggerRefresh]);

  const handlePartnerChange = async (partner: string) => {
    try {
      const fetchedConfigurations = await fetchConfigurations(partner);

      setConfigurationsFromPartner(fetchedConfigurations);
      setConfigurations(
        allConfigurations?.filter((config) => config.key.includes(partner))
      );
    } catch (error) {
      setAlerts(error, 'There was a problem generating the configurations');
    }
  };

  const handleFilterConfigurations = async (key: string) => {
    setConfigurations(
      allConfigurations?.filter((config) => config.key === key)
    );
  };

  const handleFetchConfiguration = async (searchQuery: string) => {
    try {
      setIsLoading(true);
      setConfigurations(
        allConfigurations?.filter(
          (config) =>
            config.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
            config.description
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            config.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            config.value.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleOpenModal = (
    isEdit = false,
    key?: string,
    value?: string,
    title?: string,
    description?: string
  ) => {
    setIsEdit(isEdit);
    if (isEdit) {
      setOriginalKey(key ?? '');
      setOriginalValue(value ?? '');
      setOriginalTitle(title ?? '');
      setOriginalDescription(description ?? '');
    }
    setIsConfigurationOpen(true);
  };

  const handleCloseModal = () => {
    setIsConfigurationOpen((isOpen) => !isOpen);
  };

  const handleAddConfiguration = (
    key: string,
    value: string,
    title: string,
    description: string
  ) => {
    if (allConfigurations?.some((config) => config.key === key)) {
      setShouldAlert(true);
      setAlertMessage('Error: This configuration already exists.');
      setAlertSeverity('error');
      setIsConfigurationOpen(false);
      return;
    }

    createConfiguration({ key, value, title, description })
      .then(() => {
        setShouldAlert(true);
        setAlertMessage('Configuration successfully added!');
        setAlertSeverity('success');
      })
      .catch((error: Error) => {
        setShouldAlert(true);
        setAlertMessage(`Error: ${error.message}`);
        setAlertSeverity('error');
      });

    setTriggerRefresh(true);
    setIsConfigurationOpen(false);
  };

  const handleEditConfiguration = (
    key: string,
    value: string,
    title: string,
    description: string
  ) => {
    if (originalKey !== key) {
      deleteConfiguration(originalKey).catch((error: Error) => {
        setShouldAlert(true);
        setAlertMessage(`Error: ${error.message}`);
        setAlertSeverity('error');
      });
    }
    editConfiguration({ key, value, title, description })
      .then(() => {
        setShouldAlert(true);
        setAlertMessage('Configuration successfully updated!');
        setAlertSeverity('success');
      })
      .catch((error: Error) => {
        setShouldAlert(true);
        setAlertMessage(`Error: ${error.message}`);
        setAlertSeverity('error');
      });

    setTriggerRefresh(true);
    setIsConfigurationOpen(false);
  };

  const handleDeleteConfiguration = (key: string) => {
    if (
      confirm(
        'Are you sure you want to delete this configuration?  This action cannot be undone.'
      )
    ) {
      setAllConfigurations([
        ...(allConfigurations?.filter(
          (config) => config.key !== key
        ) as Configuration[]),
      ]);

      deleteConfiguration(key)
        .then(() => {
          setShouldAlert(true);
          setAlertMessage('Configuration successfully deleted!');
          setAlertSeverity('success');
        })
        .catch((error: Error) => {
          setShouldAlert(true);
          setAlertMessage(`Error: ${error.message}`);
          setAlertSeverity('error');
        });
    }

    setTriggerRefresh(true);
    setIsConfigurationOpen(false);
  };

  const handleClearSearch = () => {
    setConfigurations(allConfigurations);
  };

  return canReadConfiguration ? (
    <Page
      header="Configurations"
      component={
        <>
          {isEdit ? (
            <AddConfigurationModal
              isEdit
              isOpen={isConfigurationOpen}
              onClose={handleCloseModal}
              onClick={handleEditConfiguration}
              setTriggerRefresh={setTriggerRefresh}
              originalKey={originalKey}
              originalValue={originalValue}
              originalTitle={originalTitle}
              originalDescription={originalDescription}
            />
          ) : (
            <AddConfigurationModal
              isEdit={false}
              isOpen={isConfigurationOpen}
              onClose={handleCloseModal}
              onClick={handleAddConfiguration}
              setTriggerRefresh={setTriggerRefresh}
            />
          )}
          <ConfigurationForm
            partners={partners}
            configurations={configurationsFromPartner as Configuration[]}
            updatePartner={(partner: string) => handlePartnerChange(partner)}
            filterConfigurations={(key: string) =>
              handleFilterConfigurations(key)
            }
          />
          <Button onClick={() => handleOpenModal(false)}>
            Add Configuration
          </Button>
          {shouldAlert && (
            <Alert variant="filled" severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </>
      }
      instructions={
        <>
          <ul>
            <li>Onboard locations to 3P (available for GrubHub only).</li>
            <li>
              Add locations to opt-in features such as Breakfast/Lunch
              Transition (blackout period), and Vehicle Descriptors.
            </li>
            <li>
              Add locations to AOR/JIT. Note: if auto-release feature is
              chainwide for a Partner, admin user can only modify the Exclude
              config.
            </li>
            <li>
              Add Locations to pilot features such as Checkout with DoorDash
              (3PiA/CwDD).
            </li>
            <li>The table will automatically update.</li>
          </ul>
          <Typography>
            Should a config option not be available in Party Portal, admin user
            can modify via the S3 update process.
          </Typography>
        </>
      }
      table={
        <>
          <Box my={2} display="flex">
            <SearchBar
              handleSearch={(str) => handleFetchConfiguration(str)}
              placeholder={'Search For Configuration'}
              handleClearSearch={handleClearSearch}
            />
            {isLoading && (
              <Box ml={3}>
                <CircularProgress />
              </Box>
            )}
          </Box>
          <ConfigurationTable
            onClick={({ isEdit, isDelete, key, value, title, description }) =>
              isDelete
                ? handleDeleteConfiguration(key as string)
                : handleOpenModal(isEdit, key, value, title, description)
            }
            {...{ configurationMappings: configurations as Configuration[] }}
          />
        </>
      }
    />
  ) : (
    <Permission />
  );
};
