import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import ContainedButton, {
  ContainedButtonVariant,
} from '../buttons/ContainedButton';

interface LoadMorePaginationProps {
  defaultFetchRows: number;
  rowsToFetch: number[];
  hasMore: () => boolean;
  onMoreClick: () => void;
  onRowFetchChange: (fetchLimit: number) => void;
}

export const LoadMorePagination = ({
  rowsToFetch,
  defaultFetchRows,
  hasMore,
  onMoreClick,
  onRowFetchChange,
}: LoadMorePaginationProps): JSX.Element => {
  const [fetchLimit, setFetchLimit] = useState(defaultFetchRows);

  const handleChange = (event: SelectChangeEvent) => {
    const newVal = parseInt(event.target.value, 10);
    setFetchLimit(newVal);
    onRowFetchChange(newVal);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item style={{ textAlign: 'center' }}>
        <ContainedButton
          id="primary"
          variant={ContainedButtonVariant.More}
          onClick={onMoreClick}
          disabled={hasMore()}
        />
      </Grid>
      <Grid item style={{ textAlign: 'center' }}>
        <FormControl>
          <InputLabel id="rows-to-fetch-select-label">
            Rows to fetch:
          </InputLabel>
          <Select
            labelId="rows-to-fetch-select-label"
            id="rows-to-fetch-select"
            value={fetchLimit.toString()}
            label="Rows to fetch"
            onChange={handleChange}
            sx={{ width: '110px' }}
          >
            {rowsToFetch.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
