import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Permission = (): JSX.Element => {
  return (
    <Box mt={5} mx={2}>
      <Typography textAlign="center" variant="h6">
        You do not have the proper permission to view this page.
      </Typography>
    </Box>
  );
};
