import { FailureDetails, SuccessDetails } from '.';
import { handleErrors, getHeaders, getBaseURL } from './utils';

export interface LocationMappingDTO {
  uuid: string;
  locationNumber: string;
  storeURL: string;
  lastUpdated: string;
}

export interface WriteLocationMappingDTO {
  uuid: string;
  locationNumber: string;
  storeURL: string;
}

export interface UpdateLocationMappingResponse {
  failure: FailureDetails[];
  success: SuccessDetails[];
}

export const getLocationMapping = async (
  partner: string
): Promise<LocationMappingDTO[]> => {
  return await fetch(`${getBaseURL()}/portal/mapping/${partner}`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(handleErrors);
};

export const addLocationMapping = async (
  partner: string,
  mapping: WriteLocationMappingDTO
): Promise<void> => {
  return await fetch(`${getBaseURL()}/portal/mapping/${partner}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(mapping),
  }).then(handleErrors);
};

export const deleteLocationMapping = async (
  partner: string,
  locationNumber: string
): Promise<void> => {
  return await fetch(
    `${getBaseURL()}/portal/mapping/${partner}/${locationNumber}`,
    {
      method: 'DELETE',
      headers: getHeaders(),
    }
  ).then(handleErrors);
};

export const updateLocationMapping = async (
  partner: string,
  locationMapping: WriteLocationMappingDTO
): Promise<void> => {
  return await fetch(
    `${getBaseURL()}/portal/mapping/${partner}/${
      locationMapping.locationNumber
    }`,
    {
      method: 'PUT',
      body: JSON.stringify(locationMapping),
      headers: getHeaders(),
    }
  ).then(handleErrors);
};

export const replaceLocationMappings = async (
  partner: string,
  formData: WriteLocationMappingDTO[]
): Promise<void> => {
  return await fetch(`${getBaseURL()}/portal/mapping/${partner}`, {
    method: 'PUT',
    body: JSON.stringify(formData),
    headers: getHeaders(),
  }).then(handleErrors);
};
