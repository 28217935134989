import React from 'react';
import * as yup from 'yup';
import { createStyles, makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import { pushMenu } from '../../api/menu';
import Dialog from '@mui/material/Dialog';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  TextField,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import TextButton, { TextButtonVariant } from '../buttons/TextButton';

interface NewMenuPushFormProps {
  onClose: () => void;
  open: boolean;
  partner: string;
  onSuccess: (message: string) => void;
}

const validationSchema = yup.object({
  locationNumber: yup
    .string()
    .required('Enter a five digit location number')
    .min(5, 'Location Number cannot be less than 5 digits')
    .max(5, 'Location Number cannot be more than 5 digits'),
});

const useStyles = makeStyles((theme) => {
  return createStyles({
    dialogTitle: {
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
  });
});

const NewMenuPushForm = ({
  onClose,
  open,
  partner,
  onSuccess,
}: NewMenuPushFormProps): JSX.Element => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      locationNumber: '',
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await pushMenu(partner, [values.locationNumber]);
        onSuccess(`Menu push requested for ${formik.values.locationNumber}`);
        formikHelpers.resetForm();
        onClose();
      } catch (err) {
        formikHelpers.setStatus(
          `Menu push failed for ${formik.values.locationNumber}, ${err}`
        );
      }
    },
    validationSchema,
  });

  return (
    <Dialog
      open={open}
      title="Push New Menu"
      maxWidth="xs"
      aria-label="menu-form"
      fullWidth
    >
      <Box py={2} px={{ xs: 2, lg: 3 }}>
        <DialogTitle className={classes.dialogTitle}>
          <Box>Push New Menu</Box>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent style={{ overflowY: 'unset' }}>
            <Box pb={3.5}>
              <TextField
                id="locationNumber"
                variant="filled"
                fullWidth
                name="locationNumber"
                label="Location Number"
                value={formik.values.locationNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.locationNumber &&
                  !!formik.errors.locationNumber
                }
                helperText={
                  formik.touched.locationNumber && formik.errors.locationNumber
                }
              />
            </Box>
            {formik.status && <Alert severity="error">{formik.status}</Alert>}
          </DialogContent>
          <DialogActions disableSpacing>
            <TextButton
              id="secondary"
              variant={TextButtonVariant.Cancel}
              onClick={() => {
                formik.resetForm();
                onClose();
              }}
            />
            <Button
              variant="contained"
              disableElevation
              color="primary"
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {formik.isSubmitting && <CircularProgress size={24} />}
              Push Menu
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default NewMenuPushForm;
