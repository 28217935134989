import { Typography, Box } from '@mui/material';
import { GHStoreInfo } from '../../api/types';

interface PartnerResultProps {
  info: GHStoreInfo | undefined;
}

export const GrubHubInfo = ({ info }: PartnerResultProps): JSX.Element => {
  return (
    <Box>
      <Typography my={1} textAlign="center">
        <strong>GrubHub</strong>
      </Typography>
      {info?.name && (
        <Typography variant="body1">{`Name: ${info?.name}`}</Typography>
      )}
      {info?.merchant_id && (
        <Typography variant="body1">{`Merchant ID: ${info?.merchant_id}`}</Typography>
      )}
      {info?.approximate_radius && (
        <Typography variant="body1">{`Delivery Radius: ${info?.approximate_radius}`}</Typography>
      )}
      {info?.account_status.accepting_online_orders && (
        <Typography variant="body1">{`Accepting Online Orders: ${info?.account_status.accepting_online_orders
          ?.toString()
          .toUpperCase()}`}</Typography>
      )}
      {info?.account_status.holds_active_account && (
        <Typography variant="body1">{`Active Account: ${info?.account_status.holds_active_account
          ?.toString()
          .toUpperCase()}`}</Typography>
      )}
      {info?.account_status.merchant_status && (
        <Typography variant="body1">{`Merchant Status: ${info?.account_status.merchant_status}`}</Typography>
      )}
      {info?.account_status.merchant_status_comments && (
        <Typography variant="body1">{`Merchant Status Comments: ${info?.account_status.merchant_status_comments}`}</Typography>
      )}
      {info?.account_status.merchant_status_descriptor && (
        <Typography variant="body1">{`Merchant Status Description: ${info?.account_status.merchant_status_descriptor}`}</Typography>
      )}
    </Box>
  );
};
export default GrubHubInfo;
