import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      height: '100%',
      padding: theme.spacing(1),
      background: 'lightgrey',
      overflowY: 'scroll',
    },
  })
);

interface WidgetProps {
  header: React.ReactNode;
  component: React.ReactNode;
  autoHeight?: boolean;
}

const Widget: React.FC<WidgetProps> = (props) => {
  const classes = useStyles();
  const { header, component, autoHeight } = props;
  return (
    <Card className={classes.card}>
      <Typography variant="h6" textAlign="center">
        {header}
      </Typography>
      <Box style={autoHeight ? { height: 'auto' } : { height: '40vh' }}>
        {component}
      </Box>
    </Card>
  );
};

export default Widget;
