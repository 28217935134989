import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { searchChangeHistory } from '../../api/change_history';
import { ChangeAudit, LastEvaluatedKey } from '../../api/types';
import { ChangeHistoryTable } from '../../components/change_history/changeHistoryTable';
import { LoadMorePagination } from '../../components/pagination/LoadMorePagination';
import { Permission } from '../../components/Permission/Permission';
import SearchBar from '../../components/searchbar/SearchBar';
import { AUDIT_READ_PERMISSION, useAuthorizer } from '../../packages/okta-auth';

export const ChangeHistory = (): JSX.Element => {
  const { hasPermission } = useAuthorizer();
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchLimit, setFetchLimit] = useState(10);
  const [lastEvalKey, setLastEvalKey] = useState<LastEvaluatedKey>();
  const [auditHistory, setAuditHistory] = useState<ChangeAudit[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const search = async (term: string, limit: number, more: boolean) => {
    try {
      setIsLoading(true);

      const paginatedChangeAudit = await searchChangeHistory(
        term,
        limit,
        more ? lastEvalKey : undefined
      );

      if (paginatedChangeAudit.results) {
        if (more) {
          paginatedChangeAudit.results =
            paginatedChangeAudit.results.concat(auditHistory);
        }

        const sortedResults = paginatedChangeAudit.results.sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return 1;
          }

          if (a.timestamp > b.timestamp) {
            return -1;
          }

          return 0;
        });

        setAuditHistory(sortedResults);
      } else {
        setAuditHistory([]);
      }

      setLastEvalKey(paginatedChangeAudit.lastEvalKey);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);

    handleHasMore();
  };

  useEffect(() => {
    search(searchTerm, fetchLimit, false);
  }, []);

  const handleFilterChangeHistory = async (term: string) => {
    setSearchTerm(term);
    search(term, fetchLimit, false);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    search('', fetchLimit, false);
  };

  const handleOnMoreClick = () => {
    search(searchTerm, fetchLimit, true);
  };

  const handleRowFetchChange = async (limit: number) => {
    setFetchLimit(limit);
    search(searchTerm, limit, false);
  };

  const handleHasMore = (): boolean => (!lastEvalKey ? true : false);
  const canReadAudit = hasPermission(AUDIT_READ_PERMISSION);
  return canReadAudit ? (
    <>
      <Container>
        <Typography variant="h3" my={3} textAlign="center">
          Change History
        </Typography>
        <Box my={2} display="flex">
          <SearchBar
            handleSearch={(str) => handleFilterChangeHistory(str)}
            placeholder={'Search For Audit'}
            handleClearSearch={handleClearSearch}
          />
          {isLoading && (
            <Box ml={3}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Container>
      <Container maxWidth={false}>
        <ChangeHistoryTable changeAudits={auditHistory} />
      </Container>
      <Container sx={{ paddingTop: '15px' }}>
        <LoadMorePagination
          rowsToFetch={[5, 10, 25, 100]}
          defaultFetchRows={10}
          hasMore={handleHasMore}
          onMoreClick={handleOnMoreClick}
          onRowFetchChange={handleRowFetchChange}
        />
      </Container>
    </>
  ) : (
    <Permission />
  );
};
