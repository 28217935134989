import React, { ReactElement, ReactNode } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return createStyles({
    dialogTitle: {
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    logo: {
      width: '250px',
    },
  });
});

export interface BasicModalProps extends DialogProps {
  isOpen: boolean;
  handleFormSubmit: (event: React.FormEvent<HTMLDivElement>) => void;
  dialogContent: ReactNode;
  dialogActions: ReactNode;
  dialogTitle: string;
  afterClose?: () => void;
  labelledBy?: string;
  ariaLabel?: string;
  maxWidth: false | 'xs' | 'md' | 'sm' | 'lg' | 'xl' | undefined;
  headerIcon?: ReactElement;
}
// TODO: Investigate the below linting error ("prop is missing in props validation")
/* eslint-disable react/prop-types */
export const BasicModal: React.VFC<BasicModalProps> = ({
  isOpen,
  handleFormSubmit,
  dialogContent,
  dialogActions,
  dialogTitle,
  afterClose,
  labelledBy = '',
  ariaLabel = '',
  maxWidth,
  headerIcon,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-label={ariaLabel}
      aria-labelledby={labelledBy}
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      //TODO: Fix me
      // PaperProps={{
      //   component: 'form',
      //   'aria-autocomplete': 'none',
      //   'aria-labelledby': labelledBy,
      //   onSubmit: handleFormSubmit,
      // }}
      onSubmit={handleFormSubmit}
      TransitionProps={{ onExited: afterClose }}
    >
      <Box py={2} px={{ xs: 2, lg: 3 }}>
        <DialogTitle id={dialogTitle} className={classes.dialogTitle}>
          <Box>
            <Box>{headerIcon}</Box>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflowY: 'unset' }}>
          {dialogContent}
        </DialogContent>
        <DialogActions disableSpacing>{dialogActions}</DialogActions>
      </Box>
    </Dialog>
  );
};

export default BasicModal;
