import { getEnv } from '../packages/config';

export const buildLogSearchUrl = (searchTerm: string): string => {
  const baseUrl = new URL(
    'https://app.datadoghq.com/logs?cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=time%2Cdesc&viz=stream&from_ts=1660921387180&to_ts=1661180587180&live=true'
  );
  baseUrl.searchParams.set(
    'query',
    `service:party-portal-service env:${getEnv()} ${searchTerm}`
  );
  return baseUrl.toString();
};
