import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import ContainedButton, {
  ContainedButtonVariant,
} from '../buttons/ContainedButton';
import BasicModal from './BasicModal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextButton, { TextButtonVariant } from '../buttons/TextButton';
import { createStyles, makeStyles } from '@mui/styles';
import useValidationMessages from '../../util/UseValidationMessages';

interface AddConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: (
    key: string,
    value: string,
    title: string,
    description: string,
    isEdit?: boolean
  ) => void;
  isEdit: boolean;
  setTriggerRefresh: Dispatch<SetStateAction<boolean>>;
  originalKey?: string;
  originalValue?: string;
  originalTitle?: string;
  originalDescription?: string;
}

export enum AddConfigurationValidationMessageTypes {
  Default = 'default',
  Key = 'key',
  Value = 'value',
  Title = 'title',
  Description = 'description',
}

const validationMessageMap = useValidationMessages([
  {
    field: AddConfigurationValidationMessageTypes.Default,
    message: 'Please make sure you have filled out the required fields.',
  },
  {
    field: AddConfigurationValidationMessageTypes.Key,
    message: 'Please enter a valid key.',
  },
  {
    field: AddConfigurationValidationMessageTypes.Value,
    message: 'Please enter a valid value.',
  },
  {
    field: AddConfigurationValidationMessageTypes.Title,
    message: 'Please enter a valid title.',
  },
  {
    field: AddConfigurationValidationMessageTypes.Description,
    message: 'Please enter a valid description.',
  },
]);

const useStyles = makeStyles((theme) => {
  return createStyles({
    secondaryButton: {
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(1),
      },
    },
    primaryButton: {
      margin: 0,
      padding: theme.spacing(1, 3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });
});

export const AddConfigurationModal: React.FC<AddConfigurationModalProps> = ({
  isOpen,
  onClose,
  onClick,
  isEdit,
  originalKey = '',
  originalValue = '',
  originalTitle = '',
  originalDescription = '',
}) => {
  const classes = useStyles();
  const [key, setKey] = useState(originalKey);
  const [value, setValue] = useState(originalValue);
  const [title, setTitle] = useState(originalTitle);
  const [description, setDescription] = useState(originalDescription);
  const [isKeyValid, setIsKeyValid] = useState(true);
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);

  useEffect(() => {
    setKey(originalKey);
    setValue(originalValue);
    setTitle(originalTitle);
    setDescription(originalDescription);
  }, [originalKey, originalValue, originalTitle, originalDescription]);

  const handleOnClose = () => {
    onClose();
  };

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKey(event.target.value);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const resetModalState = () => {
    if (isEdit) {
      return;
    } else {
      setKey('');
      setValue('');
      setTitle('');
      setDescription('');
    }
  };

  const handleOnClick = () => {
    // Check if config key is empty or not a string
    let isFormInputValid = true;
    if (key === '') {
      setIsKeyValid(false);
      isFormInputValid = false;
    }
    if (!isEdit && title === '') {
      setIsTitleValid(false);
      isFormInputValid = false;
    }
    if (!isEdit && description === '') {
      setIsDescriptionValid(false);
      isFormInputValid = false;
    }
    if (isFormInputValid) {
      onClick(key, value, title, description, isEdit);
      resetModalState();
    }
  };

  return (
    <BasicModal
      open={isOpen}
      isOpen={isOpen}
      handleFormSubmit={handleOnClick}
      maxWidth="xs"
      dialogContent={
        <Box>
          <Box pb={3.5}>
            <TextField
              id="key"
              variant="filled"
              label="Key"
              value={key}
              onChange={handleKeyChange}
              fullWidth
              error={!isKeyValid}
              helperText={
                !isKeyValid
                  ? validationMessageMap.get(
                      AddConfigurationValidationMessageTypes.Key
                    )
                  : null
              }
            />
          </Box>
          <Box pb={3.5}>
            <TextField
              id="value"
              variant="filled"
              label="Value"
              value={value}
              onChange={handleValueChange}
              fullWidth
            />
          </Box>
          <Box pb={3.5}>
            <TextField
              id="title"
              variant="filled"
              label="Title"
              value={title}
              onChange={handleTitleChange}
              fullWidth
              error={!isTitleValid}
              helperText={
                !isTitleValid
                  ? validationMessageMap.get(
                      AddConfigurationValidationMessageTypes.Title
                    )
                  : null
              }
            />
          </Box>
          <Box pb={3.5}>
            <TextField
              id="description"
              variant="filled"
              label="Description"
              value={description}
              onChange={handleDescriptionChange}
              fullWidth
              error={!isDescriptionValid}
              helperText={
                !isDescriptionValid
                  ? validationMessageMap.get(
                      AddConfigurationValidationMessageTypes.Description
                    )
                  : null
              }
            />
          </Box>
        </Box>
      }
      dialogActions={
        <>
          <TextButton
            id="primary"
            variant={TextButtonVariant.Cancel}
            onClick={handleOnClose}
            className={classes.secondaryButton}
          />
          <ContainedButton
            id="primary"
            type="submit"
            variant={
              isEdit ? ContainedButtonVariant.Edit : ContainedButtonVariant.Add
            }
            onClick={handleOnClick}
            className={classes.primaryButton}
          />
        </>
      }
      dialogTitle={isEdit ? 'Edit Configuration' : 'Add New Configuration'}
      afterClose={resetModalState}
      labelledBy="addConfiguration-form-title"
    />
  );
};

export default AddConfigurationModal;
