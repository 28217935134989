import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import { Configurations, Menus, PartnerLocationMapping } from '../../pages';
import ResponsiveAppBar from './ResponsiveAppBar';
import Admin from '../../pages/Admin';
import { ChangeHistory } from '../../pages/ChangeHistory';
import { RequireAuth } from '../auth/RequireAuth';
import {
  AUDIT_READ_PERMISSION,
  CONFIG_READ_PERMISSION,
  LOCATION_READ_PERMISSION,
  MAPPING_READ_PERMISSION,
  MENU_READ_PERMISSION,
  useAuthorizer,
} from '../../packages/okta-auth';
import { CircularProgress } from '@mui/material';

const SignOut = (): JSX.Element => {
  const result = useOktaAuth();
  result.oktaAuth.signOut();
  return <CircularProgress />;
};

export const Navigation = (): JSX.Element => {
  const { isAuthenticated } = useAuthorizer();

  return (
    <>
      {isAuthenticated && <ResponsiveAppBar />}
      <Routes>
        <Route path="/" element={<Navigate to="/admin" />} />

        <Route
          path="/admin"
          element={
            <RequireAuth requiredPermission={LOCATION_READ_PERMISSION} />
          }
        >
          <Route path="" element={<Admin />} />
        </Route>

        <Route
          path="/configurations"
          element={<RequireAuth requiredPermission={CONFIG_READ_PERMISSION} />}
        >
          <Route path="" element={<Configurations />} />
        </Route>

        <Route
          path="/menus"
          element={<RequireAuth requiredPermission={MENU_READ_PERMISSION} />}
        >
          <Route path="" element={<Menus />} />
        </Route>

        <Route
          path="/partner-location-mapping"
          element={<RequireAuth requiredPermission={MAPPING_READ_PERMISSION} />}
        >
          <Route path="" element={<PartnerLocationMapping />} />
        </Route>

        <Route
          path="/change-history"
          element={<RequireAuth requiredPermission={AUDIT_READ_PERMISSION} />}
        >
          <Route path="" element={<ChangeHistory />} />
        </Route>

        <Route path="sign-out" element={<SignOut />} />

        <Route path="/implicit/callback" element={<LoginCallback />} />
      </Routes>
    </>
  );
};
