import { LocationConfig } from '../../api/types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles(() => ({
  table: {
    display: 'block',
    maxWidth: 3500,
  },
}));

interface LocationConfigProps {
  config: LocationConfig[];
  storeID: string;
}

interface LocationConfigRowProps {
  rowConfig: LocationConfig;
  storeID: string;
  update(
    key: string,
    locations: string,
    excluded: string,
    is_included: boolean,
    is_excluded: boolean,
    error?: string
  ): void;
  canUpdateConfig?: boolean;
}

const renderTableRow = ({
  rowConfig,
}: LocationConfigRowProps): ReactElement => {
  let configButton;

  if (rowConfig.chainwide) {
    configButton = (
      <Switch
        checked={!rowConfig.is_excluded}
        disabled={!rowConfig.is_editable}
      />
    );
  } else {
    configButton = (
      <Switch
        checked={rowConfig.is_included}
        disabled={!rowConfig.is_editable}
      />
    );
  }

  let errorText;
  if (rowConfig.error != null) {
    errorText = <Alert severity="error">{rowConfig.error}</Alert>;
  } else {
    errorText = <div></div>;
  }

  return (
    <TableRow
      key={rowConfig.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Tooltip title={rowConfig.description}>
          <Typography>{rowConfig.title}</Typography>
        </Tooltip>
        {errorText}
      </TableCell>
      <TableCell component="th" scope="row">
        {configButton}
      </TableCell>
    </TableRow>
  );
};

export const LocationConfigDisplay = ({
  config,
  storeID,
}: LocationConfigProps): JSX.Element => {
  const classes = useStyles();
  const [locationConfig, setLocationConfig] = useState(config);
  useEffect(() => {
    setLocationConfig(config);
  }, [config]);
  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table sx={{ minWidth: 300 }} size="small" aria-label="Menu Mappings">
        <TableBody>
          {locationConfig
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((configMapping) =>
              renderTableRow({
                rowConfig: configMapping,
                storeID: storeID,
                update: (
                  key,
                  locations,
                  excluded,
                  is_included,
                  is_excluded,
                  error
                ) => {
                  const existing = [...locationConfig];
                  const index = existing.findIndex((c) => c.key == key);
                  if (index >= 0) {
                    existing[index].locations = locations;
                    existing[index].excluded = excluded;
                    existing[index].is_included = is_included;
                    existing[index].is_excluded = is_excluded;
                    existing[index].error = error;
                    setLocationConfig(existing);
                  }
                },
              })
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default LocationConfigDisplay;
