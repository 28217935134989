import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Alert,
  AlertColor,
  Button,
} from '@mui/material';
import SearchBar from '../../components/searchbar/SearchBar';
import { getStore } from '../../api/store';
import StoreHours from '../../components/admin/StoreHours';
import { PartnerLocationMapping } from '../../components/admin/PartnerLocationMapping';
import Widget from '../../components/admin/Widget';
import { DDStoreInfo, GHStoreInfo, UEStoreInfo, Store } from '../../api/types';
import MenuUpdates from '../../components/admin/MenuHours';
import LocationInfoDisplay from '../../components/admin/LocationInfoDisplay';
import PartnerInfoDisplay from '../../components/admin/PartnerInfoDisplay';
import LocationConfigDisplay from '../../components/admin/LocationConfigDisplay';
import useIsMounted from '../../utils/UseIsMounted';
import { MenuIDReportList } from '../../components/menu_id_report';
import { useSearchParams } from 'react-router-dom';
import CloneConfigModal from '../../components/admin/CloneConfigModal';
import {
  CONFIG_UPDATE_PERMISSION,
  useAuthorizer,
} from '../../packages/okta-auth';

const Admin = (): JSX.Element => {
  const { hasPermission } = useAuthorizer();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);

  const [shouldAlert, setShouldAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');

  const [store, setStore] = useState<Store | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchedLocationNumber = searchParams.get('id');

  const [showCloneConfigForm, setShowCloneConfigForm] = useState(false);
  const canUpdateConfig = hasPermission(CONFIG_UPDATE_PERMISSION);

  // When page loads, if there is a search query, this will automatically search for that store
  useEffect(() => {
    if (isMounted()) {
      if (!searchedLocationNumber || searchedLocationNumber === '') {
        setStore(null);
      } else {
        const paddedId = searchedLocationNumber?.padStart(5, '0');
        handleFetchStore(paddedId);
      }
    }
  }, [searchedLocationNumber]);

  const handleFetchStore = async (searchQuery: string) => {
    try {
      setIsLoading(true);
      const fetchedStore = await getStore(searchQuery);
      setStore(fetchedStore);

      if (!fetchedStore?.location) {
        setShouldAlert(true);
        setAlertMessage('Error: Store not found.');
        setAlertSeverity('error');
        setIsLoading(false);
        return;
      }

      // Menu Updates
      // Request from PP internal team to error out silently
      if (fetchedStore?.partner_results.doordash?.location_mapping_error) {
        console.error(
          'Error: Unable to load the Menu Updates/Partner Location Mapping data for DoorDash.'
        );
      }
      if (fetchedStore?.partner_results.grubhub?.location_mapping_error) {
        console.error(
          'Error: Unable to load the Menu Updates/Partner Location Mapping data for GrubHub.'
        );
      }
      if (fetchedStore?.partner_results.ubereats?.location_mapping_error) {
        console.error(
          'Error: Unable to load the Menu Updates/Partner Location Mapping data for UberEats.'
        );
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const locationNumber = store?.location?.locationNumber ?? '';

  return (
    <Container style={{ marginBottom: '1.5em' }}>
      <Typography variant="h3" my={3} textAlign="center">
        Admin
      </Typography>
      {shouldAlert && (
        <Alert
          variant="filled"
          severity={alertSeverity}
          onClose={() => setShouldAlert(false)}
        >
          {alertMessage}
        </Alert>
      )}
      <Box my={2} display="flex">
        <SearchBar
          handleSearch={(str) =>
            setSearchParams((prevParams) => {
              prevParams.set('id', str);
              return prevParams;
            })
          }
          placeholder={'Search For Store'}
        />
        {isLoading && (
          <Box ml={3}>
            <CircularProgress />
          </Box>
        )}
      </Box>

      {locationNumber ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h5" my={3} textAlign="center" display="inline">
            {`Results for Store: ${locationNumber}`}
          </Typography>
          {canUpdateConfig && (
            <Button
              onClick={() => {
                setShowCloneConfigForm(true);
              }}
            >
              Clone Config
            </Button>
          )}
        </Box>
      ) : null}
      {store?.location !== null ? (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <Widget
              header="Store Info"
              component={
                <>
                  <LocationInfoDisplay info={store?.location} />
                  <PartnerInfoDisplay
                    doordash={
                      store?.partner_results.doordash?.store_info as DDStoreInfo
                    }
                    grubhub={
                      store?.partner_results.grubhub?.store_info as GHStoreInfo
                    }
                    ubereats={
                      store?.partner_results?.ubereats
                        ?.store_info as UEStoreInfo
                    }
                  />
                </>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Widget
              autoHeight
              header="Configurations"
              component={
                <LocationConfigDisplay
                  config={store?.location_config ?? []}
                  storeID={locationNumber}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Widget
              header="Store Hours"
              component={
                <StoreHours
                  restaurant={store?.location.restaurantHours}
                  doordashFulfillmentMethod={
                    store?.location.mobileOrderingSettings.fulfillmentMethods
                      .doordash
                  }
                  uberEatsFulfillmentMethod={
                    store?.location.mobileOrderingSettings.fulfillmentMethods
                      .ubereats
                  }
                  grubHubFulfillmentMethod={
                    store?.location.mobileOrderingSettings.fulfillmentMethods
                      .grubhub
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Widget
              header="Partner Location Mapping"
              component={
                <PartnerLocationMapping
                  partnerResults={store?.partner_results}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Widget
              header="Menu Updates"
              component={
                <MenuUpdates
                  ddUpdate={store?.partner_results.doordash?.menu_update}
                  ghUpdate={store?.partner_results.grubhub?.menu_update}
                  ueUpdate={store?.partner_results.ubereats?.menu_update}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Widget
              header="Logs"
              component={
                <a target="_blank" rel="noreferrer" href={store?.datadog_url}>
                  DataDog
                </a>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Widget
              autoHeight={locationNumber !== ''}
              header="DoorDash Menu Report"
              component={<MenuIDReportList location={locationNumber ?? ''} />}
            />
          </Grid>
        </Grid>
      ) : null}
      <CloneConfigModal
        open={showCloneConfigForm}
        templateLocationNumber={locationNumber}
        onSuccess={(targetLocationNumber: string) => {
          setAlertMessage(
            `Cloned configuration from ${locationNumber} to ${targetLocationNumber}`
          );
          setAlertSeverity('success');
          setShouldAlert(true);
        }}
        onClose={() => setShowCloneConfigForm(false)}
      />
    </Container>
  );
};
export default Admin;
