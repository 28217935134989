import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { ENVS, getEnv } from '../../packages/config';

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginBottom: theme.spacing(4),
  },
  title: {
    color: 'white',
    flexGrow: 1,
  },
  button: {
    color: 'white',
  },
}));

export const MenuBar = (): JSX.Element => {
  const classes = useStyles();

  const { authState } = useOktaAuth();

  if (getEnv() !== ENVS.LOCAL && (!authState || !authState.isAuthenticated)) {
    return <></>;
  }

  return (
    <AppBar position="static" color="primary" className={classes.appBar}>
      <Toolbar>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          component={Link}
          to={'/'}
        >
          <Typography variant="h6" className={classes.title}>
            Party Portal
          </Typography>
        </Button>
        <>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.button}
            component={Link}
            to={'/configurations'}
          >
            Configurations
          </Button>

          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.button}
            component={Link}
            to={'/menus'}
          >
            Menus
          </Button>

          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.button}
            component={Link}
            to={'/partner-location-mapping'}
          >
            Partner Location Mapping
          </Button>

          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.button}
            component={Link}
            to={'/menu-id-report'}
          >
            Menu ID Report
          </Button>
        </>
      </Toolbar>
    </AppBar>
  );
};
