import { Alert, AlertColor, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { AddStoreModal } from '../../components/modals/AddStoreModal';
import {
  LocationMapping,
  LocationMappingList,
  LocationMappingForm,
} from '../../components/partner_location_mapping_container';
import { Page } from '../../components/page/Page';
import {
  addLocationMapping,
  deleteLocationMapping,
  updateLocationMapping,
} from '../../api/location_mapping';
import {
  MAPPING_UPDATE_PERMISSION,
  useAuthorizer,
} from '../../packages/okta-auth';

export const PartnerLocationMapping = (): JSX.Element => {
  const { hasPermission } = useAuthorizer();
  const [locationMappingList, setLocationMappingList] = useState<
    LocationMapping[]
  >([]);

  const [isStoreOpen, setIsStoreOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [storeId, setStoreId] = useState('');
  const [storeURL, setStoreURL] = useState('');
  const [storeUuid, setStoreUuid] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');

  const [shouldAlert, setShouldAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');

  const [filteredMappings, setFilteredMappings] = useState<LocationMapping[]>(
    []
  );

  const canUpdateLocationMapping = hasPermission(MAPPING_UPDATE_PERMISSION);

  useEffect(() => {
    setFilteredMappings(
      [...filteredMappings, ...locationMappingList].filter((it) =>
        it.storeURL.includes(selectedPartner)
      )
    );
  }, [locationMappingList]);

  const handleOpenModal = (
    isEdit = false,
    storeId = '',
    storeURL = '',
    storeUuid = ''
  ) => {
    setIsEdit(isEdit);
    setStoreId(storeId);
    setStoreURL(storeURL);
    setStoreUuid(storeUuid);
    setIsStoreOpen(true);
  };

  const handleCloseModal = () => {
    setIsStoreOpen((isOpen) => !isOpen);
  };

  const handleAddStore = (id: string, url: string, uuid?: string) => {
    if (
      locationMappingList.some(
        (it) => it.locationNumber === id.padStart(5, '0')
      )
    ) {
      setShouldAlert(true);
      setAlertMessage('Error: This store already exists.');
      setAlertSeverity('error');
    } else {
      setLocationMappingList([
        ...locationMappingList,
        {
          locationNumber: id.padStart(5, '0'),
          storeURL: url,
          uuid: selectedPartner === 'ubereats' ? uuid : '',
          lastUpdated: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
        },
      ]);
      addLocationMapping(selectedPartner, {
        locationNumber: id.padStart(5, '0'),
        storeURL: url,
        uuid: selectedPartner === 'ubereats' ? uuid ?? '' : '',
      })
        .then(() => {
          setShouldAlert(true);
          setAlertMessage('Store successfully added!');
          setAlertSeverity('success');
        })
        .catch((error: Error) => {
          setShouldAlert(true);
          setAlertMessage(`Error: ${error.message}`);
          setAlertSeverity('error');
        });
    }
    setIsStoreOpen(false);
  };

  const handleEditStore = (
    id: string,
    url: string,
    uuid?: string,
    originalId?: string
  ) => {
    setLocationMappingList([
      ...new Set([
        ...filteredMappings?.filter(
          (store) => store.locationNumber !== originalId
        ),
        {
          locationNumber: id.padStart(5, '0'),
          storeURL: url,
          uuid: selectedPartner === 'ubereats' ? uuid : '',
          lastUpdated: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
        },
      ]),
    ]);

    updateLocationMapping(selectedPartner, {
      locationNumber: id.padStart(5, '0'),
      storeURL: url,
      uuid: selectedPartner === 'ubereats' ? uuid ?? '' : '',
    })
      .then(() => {
        setShouldAlert(true);
        setAlertMessage('Store successfully edited!');
        setAlertSeverity('success');
      })
      .catch((error: Error) => {
        setShouldAlert(true);
        setAlertMessage(`Error: ${error.message}`);
        setAlertSeverity('error');
      });
    setIsStoreOpen(false);
  };

  const handleDeleteStore = (id: string) => {
    if (
      confirm(
        'Are you sure you want to delete this store?  This action cannot be undone.'
      )
    ) {
      setLocationMappingList([
        ...filteredMappings.filter((store) => store.locationNumber !== id),
      ]);

      // Backend API requires 5 char location ids
      const padded_id = id.padStart(5, '0');
      deleteLocationMapping(selectedPartner, padded_id)
        .then(() => {
          setShouldAlert(true);
          setAlertMessage('Store successfully deleted!');
          setAlertSeverity('success');
        })
        .catch((error: Error) => {
          setShouldAlert(true);
          setAlertMessage(`Error: ${error.message}`);
          setAlertSeverity('error');
        });
    }
    setIsStoreOpen(false);
  };

  return (
    <Page
      header="Partner Location Mapping"
      component={
        <>
          <AddStoreModal
            isOpen={isStoreOpen}
            onClose={handleCloseModal}
            onClick={isEdit ? handleEditStore : handleAddStore}
            isEdit={isEdit}
            id={storeId}
            url={storeURL}
            uuid={storeUuid}
            selectedPartner={selectedPartner}
          />

          <LocationMappingForm
            setLocationMappingList={setLocationMappingList}
            setSelectedPartner={setSelectedPartner}
          />
          {locationMappingList.length > 0 && canUpdateLocationMapping && (
            <Button onClick={() => handleOpenModal(false)}>Add Store</Button>
          )}
          {shouldAlert && (
            <Alert variant="filled" severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </>
      }
      instructions={
        <ul>
          <li>Validate a partners location mapping file.</li>
          <li>Run a comparison between a new and existing mapping file.</li>
          <li>Upload a new partner mapping file.</li>
        </ul>
      }
      table={
        locationMappingList.length > 0 ? (
          <LocationMappingList
            onClick={({ id, isEdit, isDelete, url, uuid }) =>
              isDelete
                ? handleDeleteStore(id)
                : handleOpenModal(isEdit, id, url, uuid)
            }
            locationMappings={locationMappingList}
          />
        ) : undefined
      }
    />
  );
};
