import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import { Permission } from '../Permission/Permission';
import { useAuthorizer } from '../../packages/okta-auth';

interface RequireAuthProps {
  requiredPermission: string;
}

export const RequireAuth = ({
  requiredPermission,
}: RequireAuthProps): JSX.Element => {
  const { oktaAuth } = useOktaAuth();
  const { isAuthenticated, hasPermission } = useAuthorizer();
  const isAuthorized = hasPermission(requiredPermission);
  useEffect(() => {
    if (!isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, isAuthenticated]);

  if (!isAuthenticated || !isAuthorized) {
    return <Permission />;
  }

  return <Outlet />;
};
