import {
  Alert,
  AlertColor,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { CheckedMenu, Menu } from '.';
import { fetchMenus, pushMenu, updateMenuChainwide } from '../../api/menu';
import { Partners } from '../../common';
import { mapMenuDTOtoMenu } from '../../mappers';
import {
  useAuthorizer,
  CHAINWIDE_MENU_PERMISSION,
} from '../../packages/okta-auth';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
      minWidth: 240,
    },
    marginBottom: 24,
  },
  storeIdFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  buttonProgress: {
    position: 'absolute',
    zIndex: 1,
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  updateResults: {
    margin: '8px',
  },
}));

interface MenuFormProps {
  setMenuList: React.Dispatch<React.SetStateAction<Menu[]>>;
  checkedMenus: CheckedMenu[];
  setSelectedPartner: React.Dispatch<React.SetStateAction<string>>;
  selectedPartner: string;
}

interface FormProps {
  partners: string[];
  selectedPartner: string;
  location: string;
  chainwide: boolean;
}

const initialValues: FormProps = {
  partners: [],
  selectedPartner: '',
  location: '',
  chainwide: false,
};

const validationSchema = Yup.object({
  selectedPartner: Yup.string().required('Required'),
  chainwide: Yup.boolean(),
});

const sortMenus = (menus: Menu[]): Menu[] => {
  return menus.sort(
    (m1, m2) => +m1.lastSuccessfulUpdate - +m2.lastSuccessfulUpdate
  );
};

const sliceMenus = (menus: Menu[], count: number): Menu[] => {
  return menus.slice(0, count - 1);
};

const filterMenus = (menus: Menu[], location: string): Menu[] => {
  const arrayOfLocations = location.split(',').map((it) => it.trim());

  return menus.filter((item) => arrayOfLocations.includes(item.locationNumber));
};

export const MenuForm = ({
  setMenuList,
  checkedMenus,
  selectedPartner,
  setSelectedPartner,
}: MenuFormProps): JSX.Element => {
  const classes = useStyles();
  const { hasPermission } = useAuthorizer();

  const [loading, setLoading] = useState(false);
  const [shouldAlert, setShouldAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');
  const [menus, setMenus] = useState<Menu[]>([]);
  const [location, setLocation] = useState('');
  const canUpdateChainwideMenus = hasPermission(CHAINWIDE_MENU_PERMISSION);

  useEffect(() => {
    setMenuList(sliceMenus(sortMenus(menus), 50));
  }, [menus]);

  useEffect(() => {
    if (location) {
      const filteredMenus = filterMenus(menus, location);

      if (filteredMenus) {
        setMenuList(sliceMenus(sortMenus(filteredMenus), 50));
      } else {
        setMenuList(new Array<Menu>());
      }
    } else {
      setMenuList(sliceMenus(sortMenus(menus), 50));
    }
  }, [location]);

  const resetAlerts = () => {
    setShouldAlert(false);
    setAlertMessage('');
    setAlertSeverity('error');
  };

  return (
    <Formik
      initialValues={{ ...initialValues, selectedPartner }}
      validationSchema={validationSchema}
      onSubmit={({ selectedPartner, chainwide }: FormProps) => {
        resetAlerts();
        setLoading(true);

        if (chainwide) {
          if (
            confirm(
              'Proceeding with this will process menus across all locations for this partner. Continue?'
            )
          ) {
            updateMenuChainwide(selectedPartner)
              .then(() => {
                setShouldAlert(true);
                setAlertMessage(
                  'A chainwide menu update has started. This may take several minutes or longer to fully complete. Monitor progress in DataDog.'
                );
                setAlertSeverity('success');
              })
              .catch((err: Error) => {
                setShouldAlert(true);
                setAlertMessage(err.message);
              });
          }
        } else {
          pushMenu(
            selectedPartner,
            checkedMenus.map((menu) => menu.locationNumber.padStart(5, '0'))
          )
            .then((result) => {
              if (result.failure.length > 0) {
                setShouldAlert(true);
                setAlertMessage(result.failure[0].reason);
              } else {
                setShouldAlert(true);
                setAlertMessage('Location menu successfully pushed!');
                setAlertSeverity('success');
              }
            })
            .catch((err: Error) => {
              setShouldAlert(true);
              setAlertMessage(err.message);
            });
        }

        setLoading(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormControl
            variant="outlined"
            size="small"
            error={!!errors.selectedPartner && touched.selectedPartner}
          >
            <InputLabel id="select-partner-label">Partner</InputLabel>
            <Select
              id="select-partner"
              name="selectedPartner"
              value={values.selectedPartner}
              labelId="select-partner-label"
              label="Partner"
              onChange={(event) => {
                resetAlerts();

                const partner = event.target.value;

                setFieldValue('selectedPartner', partner);
                setFieldValue('location', '');
                setFieldValue('chainwide', false);
                setMenuList([]);
                setSelectedPartner(partner);
                fetchMenus(partner)
                  .then((response) => {
                    const mappedMenus = response.map(mapMenuDTOtoMenu);
                    setMenus(mappedMenus);
                  })
                  .catch((err: Error) => {
                    setShouldAlert(true);
                    setAlertMessage(err.message);
                  });
              }}
            >
              {Object.values(Partners).map(({ apiFieldName, label }) => (
                <MenuItem key={label} value={apiFieldName}>
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {!!errors.selectedPartner &&
                touched.selectedPartner &&
                errors.selectedPartner}
            </FormHelperText>
          </FormControl>

          {canUpdateChainwideMenus && (
            <FormControl variant="outlined" size="small">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="chainwide"
                    onChange={handleChange}
                    checked={values.chainwide}
                    disabled={!values.selectedPartner}
                  />
                }
                label="Chainwide"
              />
            </FormControl>
          )}

          <FormControl variant="outlined" size="small">
            <TextField
              id="location"
              name="location"
              inputProps={{ 'data-testid': 'location' }}
              label="Location (Store ID)"
              variant="outlined"
              value={values.location}
              onChange={(event) => {
                Promise.resolve(handleChange(event)).then(() => {
                  const location = event.target.value;

                  setLocation(location);
                });
              }}
              size="small"
              style={{ minWidth: '340px' }}
              disabled={values.chainwide}
            />
            <FormHelperText>
              {!!errors.location && touched.location && errors.location}
            </FormHelperText>
          </FormControl>

          <Button
            variant="contained"
            disableElevation
            color="primary"
            disabled={
              loading ||
              (!(checkedMenus.length <= 0 && values.chainwide) &&
                !(checkedMenus.length > 0 && !values.chainwide))
            }
            type="submit"
          >
            Update
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>

          {shouldAlert && (
            <Alert variant="filled" severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </form>
      )}
    </Formik>
  );
};
