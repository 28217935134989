import { Typography, Box } from '@mui/material';
import {
  HolidayHours,
  HoursOfOperation,
  FulfillmentMethod,
  RestaurantHours,
  Schedulable,
} from '../../api/types';
import { addMinutes } from '../../utils/AddMinutes';

interface StoreHoursProps {
  restaurant: RestaurantHours | undefined;
  doordashFulfillmentMethod: FulfillmentMethod | undefined;
  uberEatsFulfillmentMethod: FulfillmentMethod | undefined;
  grubHubFulfillmentMethod: FulfillmentMethod | undefined;
}

const getOperatingHoursDescription = (
  openTime: string,
  duration: number,
  operationType: string
): string => {
  switch (operationType) {
    case 'open24Hours':
      return '24 Hours';
    case 'closed':
      return 'Closed';
    default:
      return `${openTime} - ${addMinutes(openTime, duration)}`;
  }
};

const getHours = (hours: HoursOfOperation): string => {
  return getOperatingHoursDescription(
    hours.operatingInterval.openTime,
    hours.operatingInterval.durationInMinutes,
    hours.operationType
  );
};

const filterOutPastHolidays = (arr: HolidayHours[]) => {
  return arr.filter(
    (holiday) => new Date(holiday.holidayDate) >= new Date()
  )[0];
};

const Schedule = ({
  header,
  fulfillmentMethod,
}: {
  header?: JSX.Element;
  fulfillmentMethod: Schedulable;
}) => {
  const mondayHours = getHours(fulfillmentMethod.hoursOfOperation.monday);
  const tuesdayHours = getHours(fulfillmentMethod.hoursOfOperation.tuesday);
  const wednesdayHours = getHours(fulfillmentMethod.hoursOfOperation.wednesday);
  const thursdayHours = getHours(fulfillmentMethod.hoursOfOperation.thursday);
  const fridayHours = getHours(fulfillmentMethod.hoursOfOperation.friday);
  const saturdayHours = getHours(fulfillmentMethod.hoursOfOperation.saturday);
  const nextHoliday = filterOutPastHolidays(
    fulfillmentMethod.hoursOfOperation.holidays ?? []
  );
  return (
    <>
      {header}
      <Typography variant="body1">{`Monday - ${mondayHours}`}</Typography>
      <Typography
        my={1}
        variant="body1"
      >{`Tuesday - ${tuesdayHours}`}</Typography>
      <Typography
        my={1}
        variant="body1"
      >{`Wednesday - ${wednesdayHours}`}</Typography>
      <Typography
        my={1}
        variant="body1"
      >{`Thursday - ${thursdayHours}`}</Typography>
      <Typography
        my={1}
        variant="body1"
      >{`Friday - ${fridayHours}`}</Typography>
      <Typography
        my={1}
        variant="body1"
      >{`Saturday - ${saturdayHours}`}</Typography>
      <Typography my={1} variant="body1">{`Sunday - closed`}</Typography>
      {nextHoliday ? (
        <Typography variant="body1">{`Next holiday - ${nextHoliday.name} (${
          nextHoliday.holidayDate
        }) - ${getOperatingHoursDescription(
          nextHoliday.holidayHours.operatingInterval.openTime,
          nextHoliday.holidayHours.operatingInterval.durationInMinutes,
          nextHoliday.holidayHours.operationType
        )}`}</Typography>
      ) : (
        <Typography variant="body1">{`Next holiday - `}</Typography>
      )}
    </>
  );
};

export const StoreHours = ({
  restaurant,
  doordashFulfillmentMethod,
  uberEatsFulfillmentMethod,
  grubHubFulfillmentMethod,
}: StoreHoursProps): JSX.Element => {
  return (
    <Box>
      {restaurant && <Schedule fulfillmentMethod={restaurant} />}

      {doordashFulfillmentMethod && (
        <Schedule
          header={
            <Typography variant="h6" my={1} textAlign="center">
              DoorDash Delivery Hours
            </Typography>
          }
          fulfillmentMethod={doordashFulfillmentMethod}
        />
      )}
      {grubHubFulfillmentMethod && (
        <Schedule
          header={
            <Typography variant="h6" my={1} textAlign="center">
              GrubHub Delivery Hours
            </Typography>
          }
          fulfillmentMethod={grubHubFulfillmentMethod}
        />
      )}
      {uberEatsFulfillmentMethod && (
        <Schedule
          header={
            <Typography variant="h6" my={1} textAlign="center">
              Uber Eats Delivery Hours
            </Typography>
          }
          fulfillmentMethod={uberEatsFulfillmentMethod}
        />
      )}
    </Box>
  );
};
export default StoreHours;
