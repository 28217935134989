// XLSX is brought in to help work with xlsx files
import * as XLSX from 'xlsx';
import {
  Alert,
  AlertColor,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik } from 'formik';
import { Partners } from '../../common';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { LocationMapping } from '.';
import { mapLocationMappingDTOtoLocationMapping } from '../../mappers';
import {
  getLocationMapping,
  replaceLocationMappings,
  WriteLocationMappingDTO,
} from '../../api/location_mapping';
import { DoorDashMapping, GrubHubMapping } from '../../api/types';
import AutoCompleteSearchBar from '../searchbar/AutoCompleteSearchBar';
import {
  useAuthorizer,
  MAPPING_UPDATE_PERMISSION,
} from '../../packages/okta-auth';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
    },
    marginBottom: 24,
  },
  storeIdFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  buttonProgress: {
    position: 'absolute',
    zIndex: 1,
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  updateResults: {
    margin: '8px',
  },
}));

interface LocationMappingFormProps {
  setLocationMappingList: React.Dispatch<
    React.SetStateAction<LocationMapping[]>
  >;
  setSelectedPartner: React.Dispatch<React.SetStateAction<string>>;
}

interface FormProps {
  partners: string[];
  selectedPartner: string;
  location: string;
}

const initialValues: FormProps = {
  partners: [],
  selectedPartner: '',
  location: '',
};

const validationSchema = Yup.object({
  selectedPartner: Yup.string().required('Required'),
});

const sortLocationMappings = (lms: LocationMapping[]): LocationMapping[] => {
  return lms.sort((m1, m2) => +m1.locationNumber - +m2.locationNumber);
};

const sliceLocationMappings = (
  lms: LocationMapping[],
  count: number
): LocationMapping[] => {
  return lms.slice(0, count - 1);
};

const filterLocationMappings = (
  lms: LocationMapping[],
  location: string
): LocationMapping[] => {
  return lms.filter((lm) => {
    return lm.locationNumber.startsWith(location);
  });
};

export const LocationMappingForm = ({
  setLocationMappingList,
  setSelectedPartner,
}: LocationMappingFormProps): JSX.Element => {
  const classes = useStyles();
  const { hasPermission } = useAuthorizer();
  const [shouldAlert, setShouldAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');
  const [locationMappings, setLocationMappings] = useState<LocationMapping[]>(
    []
  );
  const [location, setLocation] = useState('');
  const canUpload = hasPermission(MAPPING_UPDATE_PERMISSION);

  useEffect(() => {
    setLocationMappingList(
      sliceLocationMappings(sortLocationMappings(locationMappings), 2500)
    );
  }, [locationMappings]);

  useEffect(() => {
    if (location) {
      const filteredLMs = filterLocationMappings(locationMappings, location);
      if (filteredLMs) {
        setLocationMappingList(
          sliceLocationMappings(sortLocationMappings(filteredLMs), 2500)
        );
      } else {
        setLocationMappingList(new Array<LocationMapping>());
      }
    } else {
      setLocationMappingList(
        sliceLocationMappings(sortLocationMappings(locationMappings), 2500)
      );
    }
  }, [location]);

  const resetAlerts = () => {
    setShouldAlert(false);
    setAlertMessage('');
    setAlertSeverity('error');
  };

  function csvToJson(
    text: string,
    headers?: string,
    quoteChar = '"',
    delimiter = ','
  ) {
    const regex = new RegExp(
      `\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`,
      'gs'
    );

    // Type "any" is being used in place of type "string" because of a TypeScript issue with downlevel iteration.
    const match = (line: any) => {
      const matches = [...line.matchAll(regex)].map((m) => m[2]);
      matches.pop(); // Cut off blank match at the end
      return matches;
    };

    const lines = text.split('\n');
    const heads = headers ?? match(lines.shift());

    // Type "any" is being used in place of type "string" because of a TypeScript issue with downlevel iteration.
    return lines.map((line: any) => {
      return match(line).reduce((acc, cur, i) => {
        // Attempt to parse as a number; replace blank matches with `null`
        const val = cur.length <= 0 ? null : Number(cur) || cur;
        // Replace all spaces and forward slashes
        const key =
          heads[i].replaceAll(' ', '').replaceAll('/', '') ?? `extra_${i}`;
        return { ...acc, [key]: val };
      }, {});
    });
  }

  const updateLocationMappings = (
    selectedPartner: string,
    fileSelector: HTMLInputElement
  ) => {
    const reader = new FileReader();

    reader.onload = function () {
      let result;
      if (selectedPartner === 'doordash') {
        result = csvToJson(reader.result as string);
      } else {
        // Read the xlsx file and convert it to a csv
        const workbook = XLSX.read(reader.result, { type: 'binary' });
        workbook.SheetNames.forEach((sheet) => {
          result = csvToJson(XLSX.utils.sheet_to_csv(workbook.Sheets[sheet]));
        });
      }

      const updatedLocationMappings =
        selectedPartner === 'doordash' && result
          ? result.map((store: DoorDashMapping) => {
              return {
                locationNumber: store.CFAStoreName.replace(/\D/g, ''),
                storeURL: store.WeblinkURL ?? '',
                uuid: '',
              };
            })
          : selectedPartner === 'grubhub' && result
          ? result
              .map((store: GrubHubMapping) => {
                return {
                  locationNumber: store?.StoreNumber?.toString().padStart(
                    5,
                    '0'
                  ),
                  storeURL: store.Link,
                  uuid: '',
                };
              })
              .filter(
                (store: WriteLocationMappingDTO) =>
                  store?.locationNumber !== undefined
              )
          : selectedPartner === 'ubereats' && result
          ? result
              ?.filter(
                // This needs to remain as type "any" because of the dynamic date launch key (it always starts with a number ie 323Lauch).
                (store: any) => {
                  const keyWithDigitsPrefix = Object.keys(store).find((key) =>
                    key.match(/^\d+/)
                  );
                  const keyIsPrefixedWithDigitsAndIsNotNull =
                    keyWithDigitsPrefix && store[keyWithDigitsPrefix] !== null;

                  return (
                    store?.is_visible === 'TRUE' ||
                    keyIsPrefixedWithDigitsAndIsNotNull
                  );
                }
              )
              // This needs to remain as type "any" because of the dynamic date launch key (it always starts with a number ie 323Lauch).
              .map((store: any) => {
                return {
                  locationNumber: store?.external_store_id
                    ?.toString()
                    .padStart(5, '0'),
                  storeURL: store?.UEURL,
                  uuid: store?.uuid,
                };
              })
          : [];

      replaceLocationMappings(selectedPartner, updatedLocationMappings)
        .then(() => {
          setShouldAlert(true);
          setAlertMessage('Location mapping successfully updated!');
          setAlertSeverity('success');
        })
        .catch((err: Error) => {
          setShouldAlert(true);
          setAlertMessage(err.message);
        });
    };
    // Start reading the file. When it is done, calls the onload event defined above.
    if (fileSelector.files) reader.readAsBinaryString(fileSelector.files[0]);
  };

  const autoCompleteSet = new Set(
    locationMappings.map((it) => (it.locationNumber ? it.locationNumber : ''))
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ selectedPartner }: FormProps) => {
        resetAlerts();
        const xlsx =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.onchange = (event: Event): void => {
          const input = event.target as HTMLInputElement;

          if (!input.files?.length) {
            return;
          }
          const file = input.files[0];

          if (
            (selectedPartner === 'doordash' && file.type !== 'text/csv') ||
            (selectedPartner === 'grubhub' && file.type !== xlsx) ||
            (selectedPartner === 'ubereats' && file.type !== xlsx)
          ) {
            setShouldAlert(true);
            setAlertMessage(`Please choose a valid file type.`);
            return;
          }

          updateLocationMappings(selectedPartner, fileSelector);
        };
        fileSelector.click();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <FormControl
            variant="outlined"
            size="small"
            error={!!errors.selectedPartner && touched.selectedPartner}
          >
            <InputLabel id="select-partner-label">Partner</InputLabel>
            <Select
              id="select-partner"
              name="selectedPartner"
              value={values.selectedPartner}
              labelId="select-partner-label"
              label="Partner"
              onChange={(event) => {
                resetAlerts();
                const partner = event.target.value;
                setSelectedPartner(partner);
                setFieldValue('selectedPartner', partner);
                setFieldValue('location', '');
                setLocationMappingList([]);

                getLocationMapping(partner)
                  .then((response) => {
                    const mappedLMs = response.map(
                      mapLocationMappingDTOtoLocationMapping
                    );
                    setLocationMappings(mappedLMs);
                  })
                  .catch((err: Error) => {
                    setShouldAlert(true);
                    setAlertMessage(err.message);
                  });
              }}
            >
              {Object.values(Partners).map(({ apiFieldName, label }) => (
                <MenuItem key={label} value={apiFieldName}>
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>

            <FormHelperText>
              {!!errors.selectedPartner &&
                touched.selectedPartner &&
                errors.selectedPartner}
            </FormHelperText>
          </FormControl>

          <FormControl variant="outlined" size="small">
            <AutoCompleteSearchBar
              placeholder="Location (Store ID)"
              onChange={(event, newValue) => {
                Promise.resolve(handleChange(event)).then(() => {
                  setLocation(newValue ?? '');
                });
              }}
              autoCompleteOptions={[...autoCompleteSet]}
            />
            <FormHelperText>
              {!!errors.location && touched.location && errors.location}
            </FormHelperText>
          </FormControl>

          {canUpload && (
            <Button
              variant="contained"
              disableElevation
              color="primary"
              type="submit"
            >
              UPLOAD FILE
            </Button>
          )}

          {shouldAlert && (
            <Alert variant="filled" severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </form>
      )}
    </Formik>
  );
};
