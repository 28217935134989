import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { ChangeAudit } from '../../api/types';

interface ChangeAuditProps {
  changeAudits: ChangeAudit[];
}

const renderTableRow = ({
  id,
  timestamp,
  login,
  product,
  feature,
  property,
  newValue,
  oldValue,
}: ChangeAudit): ReactElement => {
  return (
    <TableRow
      key={id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Typography>{new Date(timestamp).toLocaleString()}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{login}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{product}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{feature}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{property}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{newValue}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{oldValue === '' ? 'N/A' : oldValue}</Typography>
      </TableCell>
    </TableRow>
  );
};

export const ChangeHistoryTable = ({
  changeAudits,
}: ChangeAuditProps): JSX.Element => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} size="small" aria-label="Change History">
        <TableHead>
          <TableRow>
            <TableCell>Date/Time</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Feature</TableCell>
            <TableCell>Property</TableCell>
            <TableCell>New Value</TableCell>
            <TableCell>Old Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {changeAudits.map((audit) => renderTableRow({ ...audit }))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
