import { getBaseURL, getHeaders, handleErrors } from '../api/utils';
import { Store } from './types';

export const getStore = async (storeId: string): Promise<Store> => {
  const paddedStoreId = storeId.padStart(5, '0');
  return await fetch(`${getBaseURL()}/portal/location/${paddedStoreId}`, {
    method: 'GET',
    headers: getHeaders(),
  }).then(handleErrors);
};
