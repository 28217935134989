import { Typography, Box } from '@mui/material';
import { LocationInfo } from '../../api/types';

interface LocationInfoProps {
  info: LocationInfo | undefined;
}

export const LocationInfoDisplay = ({
  info,
}: LocationInfoProps): JSX.Element => {
  return (
    <Box>
      <Typography variant="body1">{info?.name}</Typography>
      <Typography variant="body1">
        {info?.locationContact.streetAddress.address1}
      </Typography>
      <Typography variant="body1">
        {info?.locationContact?.streetAddress &&
          `${info?.locationContact.streetAddress.city ?? ''}, ${
            info?.locationContact.streetAddress.state ?? ''
          }, ${info?.locationContact.streetAddress.zipCode.zip ?? ''}`}
      </Typography>
      <Typography variant="body1">{info?.status}</Typography>
      {info?.subStatus && (
        <Typography variant="body1">{info?.subStatus}</Typography>
      )}
      <Typography variant="body1">
        <a target="_blank" rel="noreferrer" href={info?.marketableUrl ?? ``}>
          {info?.marketableUrl ?? ``}
        </a>
      </Typography>
    </Box>
  );
};
export default LocationInfoDisplay;
