import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';

const useStyles = makeStyles(() => ({
  table: {
    display: 'block',
    maxWidth: 3500,
  },
}));

export interface LocationMappingFile {
  partner: string;
  inLocationMappingFile: boolean;
  webLink?: string;
}

interface Props {
  partners: LocationMappingFile[];
}

const renderTableRow = ({
  partner,
  inLocationMappingFile,
  webLink,
}: LocationMappingFile): ReactElement => {
  return (
    <TableRow
      key={partner}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {partner}
      </TableCell>
      <TableCell>
        {inLocationMappingFile ? (
          <CheckCircleRoundedIcon style={{ color: 'green' }} />
        ) : (
          <DangerousRoundedIcon style={{ color: 'red' }} />
        )}
      </TableCell>
      <TableCell>
        {inLocationMappingFile ? (
          <a target="_blank" rel="noreferrer" href={webLink}>
            {webLink}
          </a>
        ) : (
          webLink
        )}
      </TableCell>
    </TableRow>
  );
};

export const PartnerLocationMappingTable = ({
  partners,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table sx={{ minWidth: 300 }} size="small" aria-label="Menu Mappings">
        <TableBody>
          {partners.map((menuMapping) => renderTableRow({ ...menuMapping }))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
