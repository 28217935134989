// TODO: Fix `any` type
//eslint-disable-next-line
export const addMinutes = (time: any, minsToAdd: string | number) => {
  //eslint-disable-next-line
  function D(J: any) {
    return (J < 10 ? '0' : '') + J;
  }
  const piece = time.split(':');
  const mins = piece[0] * 60 + +piece[1] + +minsToAdd;

  return D(((mins % (24 * 60)) / 60) | 0) + ':' + D(mins % 60);
};
