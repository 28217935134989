import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactElement, useState } from 'react';
import TableFooterPagination from '../pagination/TableFooterPagination';
import {
  MAPPING_UPDATE_PERMISSION,
  useAuthorizer,
} from '../../packages/okta-auth';

export interface LocationMapping {
  locationNumber: string;
  storeURL: string;
  lastUpdated: string;
  uuid?: string;
  onClick?: (args: {
    id: string;
    isEdit: boolean;
    isDelete: boolean;
    url?: string;
    uuid?: string;
  }) => void;
  canUpdateMapping?: boolean;
}

interface LocationMappingListProps {
  locationMappings: LocationMapping[];
  onClick?: (args: {
    id: string;
    isEdit: boolean;
    isDelete: boolean;
    url?: string;
    uuid?: string;
  }) => void;
}
const buildLocationUrl = (locationNumber: string) => {
  const paddedLocationNumber = locationNumber.padStart(5, '0');

  return `https://portal.cfahome.com/search/?Query=${paddedLocationNumber}`;
};

const renderTableRow = ({
  uuid,
  locationNumber,
  storeURL,
  lastUpdated,
  onClick,
  canUpdateMapping,
}: LocationMapping): ReactElement => {
  const handleEdit = (id: string, url: string) => {
    if (onClick) onClick({ id, isEdit: true, isDelete: false, url, uuid });
  };

  const handleDelete = (id: string) => {
    if (onClick) onClick({ id, isEdit: false, isDelete: true });
  };

  return (
    <TableRow
      key={locationNumber + storeURL}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      {uuid ? (
        <TableCell component="th" scope="row">
          {uuid}
        </TableCell>
      ) : null}

      <TableCell component="th" scope="row">
        <a
          target="_blank"
          rel="noreferrer"
          href={buildLocationUrl(locationNumber)}
        >
          {locationNumber.padStart(5, '0')}
        </a>
      </TableCell>
      <TableCell>
        <a target="_blank" rel="noreferrer" href={storeURL}>
          {storeURL}
        </a>
      </TableCell>
      <TableCell>
        <Typography>{lastUpdated}</Typography>
      </TableCell>
      {canUpdateMapping && (
        <>
          <TableCell>
            <Button onClick={() => handleEdit(locationNumber, storeURL)}>
              Edit
            </Button>
          </TableCell>
          <TableCell>
            <Button onClick={() => handleDelete(locationNumber)}>Delete</Button>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export const LocationMappingList = ({
  locationMappings,
  onClick,
}: LocationMappingListProps): JSX.Element => {
  const { hasPermission } = useAuthorizer();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const uniqueMappings = Array.from(
    new Set(locationMappings.map((a) => a.locationNumber))
  ).map(
    (locationNumber) =>
      locationMappings.find(
        (a) => a.locationNumber === locationNumber
      ) as LocationMapping
  );

  const canUpdateMapping = hasPermission(MAPPING_UPDATE_PERMISSION);
  const tableRows = [
    ...uniqueMappings
      .sort((a, b) => {
        const store1 = a.locationNumber;
        const store2 = b.locationNumber;
        return store1 === store2 ? 0 : store1 > store2 ? 1 : -1;
      })
      .map((location) =>
        renderTableRow({
          ...location,
          onClick,
          canUpdateMapping,
        })
      ),
  ];

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} size="small" aria-label="Location Mappings">
        <TableHead>
          <TableRow>
            {locationMappings.some((it) => it.uuid) ? (
              <TableCell>UUID</TableCell>
            ) : null}
            <TableCell>Store</TableCell>
            <TableCell>Url</TableCell>
            <TableCell>Last Updated</TableCell>
            {canUpdateMapping && (
              <>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {locationMappings.length > 0
            ? rowsPerPage > 0
              ? tableRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableRows
            : null}
        </TableBody>
        <TableFooterPagination
          count={uniqueMappings.length ?? 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          colSpan={6}
        />
      </Table>
    </TableContainer>
  );
};
